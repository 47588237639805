.preference-time {
  .preference-time_table {
    height: 100%;
    width: 100%;
  }

  .preference-time_slots {
    background-color: #e6ebf0;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: #8ca6c0;
    text-align: center;
    // width: 200px;
  }
  .preference-time-check-icon {
    color: #5cdb95;
  }
  .preference-time_tablehead {
    background-color: #e6ebf0;
  }

  .preference-time_content {
    background-color: #e6ebf0;
  }
  .preference-time_tablehead1 {
    margin-top: 12px;
  }

  .preference-time_columnhead {
    width: 93px;
    height: 74px;
  }

  .preference-time_head1 {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: #8ca6c0;
    padding-bottom: 6px;
  }
  .preference-time_span {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #8ca6c0;
    margin-bottom: 15px;
  }
}
