/// All these rules have width and height the same as the font-size because
/// some icons are font faces while some are SVGs or PNGs.

@mixin icon($font-size, $width, $height) {
  // Display.
  display: flex;
  align-items: center;
  justify-content: center;

  // Sizing.
  width: $width;
  height: $height;
  font-size: $font-size;

  & > * {
    width: $font-size;
    height: $font-size;
  }

  // Colors.
  &,
  & > * {
    color: inherit;
  }
}

@mixin icon-rounded($font-size, $width, $height) {
  border-radius: 50%;
  padding: calc(#{$width} / 4);
  @include icon($font-size, $width, $height);
}

// Default.
.icon-xs {
  @include icon($font-size: 16px, $width: 16px, $height: 16px);
}

.icon-sm {
  @include icon($font-size: 24px, $width: 24px, $height: 24px);
}

.icon-md {
  @include icon($font-size: 32px, $width: 32px, $height: 32px);
}

.icon-lg {
  @include icon($font-size: 40px, $width: 40px, $height: 40px);
}

.icon-xl {
  @include icon($font-size: 48px, $width: 48px, $height: 48px);
}

// Rounded.
.icon-rounded-xs {
  @include icon-rounded($font-size: 12px, $width: 24px, $height: 24px);
}

.icon-rounded-sm {
  @include icon-rounded($font-size: 16px, $width: 32px, $height: 32px);
}

.icon-rounded-md {
  @include icon-rounded($font-size: 20px, $width: 40px, $height: 40px);
}

.icon-rounded-lg {
  @include icon-rounded($font-size: 24px, $width: 48px, $height: 48px);
}

.icon-rounded-xl {
  @include icon-rounded($font-size: 28px, $width: 56px, $height: 56px);
}