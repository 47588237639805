.navbar-nav {
  margin-left: auto;
}

.primary-color-button-fix {
  background-color: #05386b !important;
  color: white;
}
.navbar {
  overflow: hidden;
  /* background-color: #333; */
  position: fixed;
  top: 0;
  width: 100%;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
.user-details {
  margin-right: 10px;
}
.startSidebar {
  /* margin: 0px;
  padding: 0px; */
  /* width: 356px; */
  background-color: white;
  /* height: 900px; */
  /* position: fixed; */
  left: 0;
  top: 0;
  position: absolute;
  /* margin-top: 77px; */
}
.sidebar-menu {
  width: 100%;
  margin-bottom: 1rem;
  padding-left: 2rem;
}
.setPayStyle {
  margin-left: 107px;
}
.paraSetMargin {
  margin-left: 53px;
}
.setSpMargin {
  margin-left: 60px;
}

.line {
  position: absolute;
  width: 436px;
  height: 0px;
  left: 43px;
  top: 130px;

  /* ☑️ Style v0.2/Neutral/04 */

  border: 1px solid #e6ebf0;
  transform: rotate(90deg);
}

.setupText {
  position: static;
  width: 152px;
  height: 24px;
  left: 48px;
  top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  color: #05386b;
  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 8px;
}

.proComTextStyle {
  position: static;
  width: 128px;
  height: 24px;
  left: 49px;
  top: 12px;
  /* ☑️ Style v0.2/Headings/05 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  /* margin: 0px 4px; */
  /* margin-top: -20px; */
}

#myProgress2 {
  width: 100%;
  background-color: #ddd;
  margin-top: -30px !important;
}

#myBars2 {
  width: 8%;
  height: 6px;
  background-color: #5cdb95;
  text-align: center;
  line-height: 30px;
  color: white;
}

.vertical {
  border-left: 2px solid black;
  height: 800px;
}

.bioTextStyling {
  position: static;
  left: 0%;
  right: 97.43%;
  top: 0%;
  bottom: 76.19%;
  /* ☑️ Style v0.2/Captions/Label */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  /* ☑️ Style v0.2/Neutral/01 */
  color: #3e6d9c;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.bioPragraphStyling {
  position: static;
  left: 1.54%;
  right: 1.54%;
  top: 14.29%;
  bottom: 14.29%;

  /* ☑️ Style v0.2/Forms/01 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 10px;
}

.tenTextStyle {
  position: static;
  /* width: 269px; */
  height: 36px;
  left: 0px;
  top: 0px;
  /* ☑️ Style v0.2/Headings/02 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}

.onboarding-tabs-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
  color: #05386b;
  margin-bottom: 2rem;
  padding-bottom: 4px;
  border-bottom: 3px solid rgb(var(--bs-success-rgb));
}
.onboarding-label {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #3e6d9c;
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

.fillText {
  position: static;
  width: 509px;
  height: 20px;
  left: 0px;
  top: 36px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #3e6d9c;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
  word-wrap: break-word;
}

.paComTextStyle {
  position: static;
  width: 181px;
  height: 40px;
  left: 47px;
  top: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #3e6d9c;
  flex: none;
  order: 0;
  flex-grow: 0;
  /* margin: 0px 10px; */
  margin-left: 50px;
}
.setSSTylings {
  margin-left: 87px;
}
.setTACStyles {
  margin-left: 83px;
}
.setSPStyles {
  margin-left: 102px;
}
.setAvStylings {
  margin-left: 84px;
}
.checkIcon {
  color: #5cdb95;
}
.csucStyles {
  margin-left: 4px;
}

.bTextStyle {
  position: static;
  width: 140px;
  height: 24px;
  left: 0px;
  top: 0px;
  /* ☑️ Style v0.2/Body/Medium */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  /* margin: 2px 4px; */
  margin-left: 10px;
}
.skSet {
  margin-left: 60px;
}
.suSet {
  margin-left: 60px;
}
.cerCheckSet {
  margin-left: 0px;
}
.ceTextSet {
  margin-left: 10px;
}
.exsSet {
  margin-left: 60px;
}
.certSet {
  margin-left: 60px;
}
.skCheckSet {
  margin-left: 89px;
}
.exCheckSet {
  margin-left: 73px;
}
.exTextSet {
  margin-left: 10px;
}
.quCheckSet {
  margin-left: 61px;
}

.Qualifications {
  margin-left: 10px;
}
.quSet {
  margin-left: 60px;
}
.basCheckSet {
  margin-left: 41px;
}
.basTextSet {
  margin-left: 5px;
}
.setMarginBaseCheck {
  margin-left: 40px;
}
.setMarginBusines {
  margin-left: 60px;
}
.qTextStyle {
  position: static;
  width: 164px;
  height: 24px;
  left: 0px;
  top: 0px;
  /* ☑️ Style v0.2/Body/Medium */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Neutral/02 */
  color: #8ca6c0;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  /* margin: 0px 0px; */
}

.expTextStyle {
  position: static;
  width: 164px;
  height: 24px;
  left: 0px;
  top: 0px;
  /* ☑️ Style v0.2/Body/Medium */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Neutral/02 */
  color: #8ca6c0;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  /* margin: 0px 4px; */
  margin-left: 10px;
}
.ExpSetMarginCheck {
  margin-left: 69px;
}
.SetMarginBusIcon {
  margin-left: 60px;
}
.QualSetMarCheck {
  margin-left: 57px;
}
.SetMarStarIcon {
  margin-left: 60px;
}
.SetMarSkillCheck {
  margin-left: 90px;
}
.PageMar {
  margin-left: 60px;
}
.businIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  margin-top: 30px;
}

.skillTextStyle {
  position: static;
  width: 164px;
  height: 24px;
  left: 0px;
  top: 0px;

  /* ☑️ Style v0.2/Body/Medium */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Neutral/02 */

  color: #8ca6c0;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 4px;
  margin-left: 10px;
}
.skTextSet {
  margin-left: 10px;
}
.starIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  margin-top: 30px;
  color: #8ca6c0;
}

.certTextStyle {
  position: static;
  width: 164px;
  height: 24px;
  left: 0px;
  top: 0px;

  /* ☑️ Style v0.2/Body/Medium */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Neutral/02 */

  color: #8ca6c0;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 4px;
  margin-left: 10px;
}
.SetCertiCheck {
  margin-left: 0;
}
.SetSubMar {
  margin-left: 60px;
}
.certIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  margin-top: 30px;
  color: #8ca6c0;
}
.sTStyles {
  position: static;
  width: 164px;
  height: 24px;
  left: 0px;
  top: 0px;
  /* ☑️ Style v0.2/Body/Medium */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Neutral/02 */
  color: #8ca6c0;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  /* margin: 0px 4px; */
  margin-left: 7px;
}

.subTextStyle {
}
.SetSubCheckMar {
  margin-left: 85px;
}
.setSecondPayCheck {
  margin-left: 98px;
}
.SetTCMar {
  margin-left: 80px;
}
.SetTMparaMar {
  margin-left: 53px;
}
.SetComParaMar {
  margin-left: 53px;
}
.CreSetCheckMar {
  margin-left: 42px;
}
.SetCreParaMar {
  margin-left: 53px;
}
.SetAvaCheck {
  margin-left: 80px;
}
.subIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  margin-top: 30px;
  color: #8ca6c0;
}

.pIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  margin-top: 30px;
}
.SetAvaParaMar {
  margin-left: 53px;
}
.dharmikStyling {
  margin-left: 330px;
  margin-top: -2427px;
}
.HandFontSize {
  font-size: 24px;
}
.ProCompSetStyle {
  margin-left: 1180px;
  width: 250px;
  margin-top: 15px;
  height: 257px;
}
.infoSetStyles {
  margin-left: 1180px;
  width: 250px;
  margin-top: 15px;
  height: 120px;
}
.helpCenStyles {
  margin-left: 1180px;
  width: 250px;
  margin-top: 15px;
  height: 70px;
}
.binfoDivStyles {
  margin-left: 360px;
  width: 800px;
  margin-top: -475px;
}
.binfoImageStyles {
  height: 100px;
}
.binfoDStyls {
  background: #f8f8fb;
}
.covImageSetStyles {
  width: 50px;
}
.upLoadButtonStyles {
  background: #05386b;
  height: 36px;
}
.cgIconStyles {
  font-size: 20px;
  color: #05386b;
}
.bInfo2Styles {
  margin-left: 360px;
  width: 800px;
  margin-top: 30px;
}
.paymentSetup1 {
  padding: 2px 5px;
  font-size: 19px;

  /* color: white; */
  /* background-color:#ced4da; */
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
}
.bInfo3Styles {
  margin-left: 360px;
  width: 800px;
  margin-top: 30px;
}
.skillDivStyles {
  margin-left: 360px;
  width: 800px;
  margin-top: 30px;
}
.adButtonStyles {
  background: #05386b;
  color: white;
  height: 37px;
}
.bsxStyles {
  margin-left: 48px;
}

.laraBsXStyles {
  margin-left: 37px;
}
.wDesignBsXStyles {
  margin-left: 3px;
}
.phBsXStyles {
  margin-left: 10px;
}
.othCertiDivStyles {
  margin-left: 360px;
  width: 800px;
  margin-top: 30px;
}
.RVideoStyles {
  background: #f8f8fb;
  height: 200px;
}
.rndedStyles {
  width: 90px;
  background: #f8f8fb;
}
.subDivStyles {
  margin-left: 360px;
  width: 800px;
  margin-top: 30px;
}
.reRecStyles {
  height: 42px;
}
.saBStyles {
  height: 41px;
}
.larBsXStyles {
  margin-left: 37px;
}
.photoBsXStyles {
  margin-left: 10px;
}
.schIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  font-size: 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  /* margin-top: 30px; */
}

.businIcon {
  /* position: absolute; */
  color: #8ca6c0;
  /* ☑️ Style v0.2/Neutral/02 */
  border: 1.25px solid #8ca6c0;
  margin-top: 20px;
}

.inTextStylings {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #8ca6c0;
}

.helpCardStyling {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  position: absolute;
  /* width: 267px; */
  /* height: 64px; */
  left: 1149px;
  top: 576px;
  color: #05386b;
  /* ☑️ Style v0.2/Emotinal/Success/Light */

  background: #acf1d1;
  border-radius: 4px;
}

.help-card {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.helpCenterTextStyles {
  /* ☑️ Style v0.2/Headings/05 */
  margin-left: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  /* flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 8px; */
}
.bioLang {
  position: static;
  left: 0%;
  right: 54.17%;
  top: 0%;
  bottom: 0%;

  /* ☑️ Style v0.2/Captions/Label */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  /* ☑️ Style v0.2/Neutral/01 */

  color: #3e6d9c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}
.descTextStyling {
  position: static;
  left: 0%;
  right: 97.43%;
  top: 0%;
  bottom: 76.19%;
  /* ☑️ Style v0.2/Captions/Label */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  /* ☑️ Style v0.2/Neutral/01 */
  color: #3e6d9c;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}
.descPragraphStyling {
  position: static;
  left: 1.54%;
  right: 1.54%;
  top: 14.29%;
  bottom: 14.29%;

  /* ☑️ Style v0.2/Forms/01 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 10px;
}
.recordTextStyling {
  position: static;
  width: 131px;
  height: 24px;
  left: 0px;
  top: 0px;

  /* ☑️ Style v0.2/Headings/05 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}
.VideoIcons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: static;
  font-size: 10px;
  /* ☑️ Style v0.2/Neutral/06 */
  background: #ffffff;
  /* ☑️ Style v0.2/Neutral/04 */
  /* border: 1px solid #E6EBF0; */
  box-sizing: border-box;
  border-radius: 50px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 350px;
}
.bioMax5 {
  position: static;
  width: 70px;
  height: 16px;
  left: 74px;
  top: 2px;

  /* ☑️ Style v0.2/Captions/Caption Small */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */
  /* ☑️ Style v0.2/Neutral/02 */
  color: #8ca6c0;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}
.skillsTextStyle {
  position: static;
  width: 61px;
  height: 24px;
  left: 0px;
  top: 0px;

  /* ☑️ Style v0.2/Headings/05 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}
.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #5cdb95;
}
.arrowIconStyling {
  color: #05386b;
  margin-left: 60px;
}
.expSets {
  margin-left: 60px;
}
.stimSt {
  margin-bottom: -240px;
}
.sthiSt {
  font-size: 24px;
}
.sthrSt {
  width: 350px;
  margin-top: 0px;
  border: 1px solid #5cdb95;
}
.stRPSt {
  width: 90px;
  background: #f8f8fb;
}
.sVBSt {
  width: 110px;
  color: #05386b;
}
.warnTextStyling {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  position: static;
  /* width: 407px; */
  height: 36px;
  /* left: 370px; */
  top: 0px;
  /* ☑️ Style v0.2/Emotinal/Warning/Light */
  background: #fefbe8;
  /* ☑️ Style v0.2/Emotinal/Warning/Dark */
  border: 1px solid #f3d11a;
  box-sizing: border-box;
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 183px;
}
.warnIconStyling {
  color: #f3d11a;
  font-size: 24px;
  margin-bottom: 2px;
}
.warningTextStyling {
  /* position: static; */
  width: 66px;
  height: 24px;
  left: 32px;
  top: 0px;
  /* ☑️ Style v0.2/Headings/05 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}
.wrnMesgTextStyle {
  position: static;
  width: 237px;
  height: 24px;
  left: 126px;
  top: 6px;
  /* ☑️ Style v0.2/Body/Medium */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 16px;
}
.crosIconStyle {
  color: black;
  font-size: 7px;
  margin-left: 48px;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e6d9c;
  background-color: #ffffff;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e); */
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
.uploadIconStyling {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 25px;
  color: #05386b;
  position: static;
  max-width: 777px;
  height: 36px;
  left: 0px;
  top: 28px;
  /* ☑️ Style v0.2/Neutral/06 */
  background: #ffffff;
  /* ☑️ Style v0.2/Neutral/04 */
  border: 1px dashed #e6ebf0;
  box-sizing: border-box;
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

.upload-certificate {
  display: flex;
  padding: 18px 25px;
  height: 36px;
  flex-direction: row;
  align-items: center;
  color: #05386b;
  background: #ffffff;
  border: 1px dashed #e6ebf0;
  box-sizing: border-box;
  border-radius: 4px;
}
.selectTextStyling {
  position: static;
  left: 3.15%;
  right: 71.88%;
  top: 22.22%;
  bottom: 22.22%;
  /* ☑️ Style v0.2/Forms/01 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.cameraImage {
  margin-left: 20px;
  /* margin-bottom: -205px; */
  /* margin-top: -10px; */
}
.setUploadButton {
  margin-top: 60px;
}
.round {
  position: relative;
}
.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox2 start*/
.round2 {
  position: relative;
}
.round2 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round2 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round2 input[type="checkbox"] {
  visibility: hidden;
}

.round2 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round2 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox2 end*/
/*checkbox3 start*/
.round3 {
  position: relative;
}
.round3 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round3 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round3 input[type="checkbox"] {
  visibility: hidden;
}

.round3 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round3 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox3 end*/
/*checkbox4 start*/
.round4 {
  position: relative;
}
.round4 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round4 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round4 input[type="checkbox"] {
  visibility: hidden;
}

.round4 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round4 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox4 end*/
/*checkbox5 start*/
.round5 {
  position: relative;
}
.round5 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round5 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round5 input[type="checkbox"] {
  visibility: hidden;
}

.round5 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round5 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox5 end*/
/*checkbox6 start*/
.round6 {
  position: relative;
}
.round6 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round6 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round6 input[type="checkbox"] {
  visibility: hidden;
}

.round6 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round6 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox6 end*/
/*checkbox7 start*/
.round7 {
  position: relative;
}
.round7 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round7 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round7 input[type="checkbox"] {
  visibility: hidden;
}

.round7 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round7 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox7 end*/
/*checkbox8 start*/
.round8 {
  position: relative;
}
.round8 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.round8 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 12px;
}

.round8 input[type="checkbox"] {
  visibility: hidden;
}

.round8 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round8 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox8 end*/
/*checkbox9 start*/
.round9 {
  position: relative;
}
.round9 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round9 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round9 input[type="checkbox"] {
  visibility: hidden;
}

.round9 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round9 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox9 end*/
/*checkbox10 start*/
.round10 {
  position: relative;
}
.round10 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round10 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round10 input[type="checkbox"] {
  visibility: hidden;
}

.round10 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round10 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox10 end*/
/*checkbox11 start*/
.round11 {
  position: relative;
}
.round11 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round11 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round11 input[type="checkbox"] {
  visibility: hidden;
}

.round11 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round11 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox11 end*/
/*checkbox12 start*/
.round12 {
  position: relative;
}
.round12 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round12 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round12 input[type="checkbox"] {
  visibility: hidden;
}

.round12 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.round12 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox12 end*/
.HiTextStyle {
  position: static;
  width: 194px;
  height: 36px;
  left: 0px;
  top: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #05386b;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  word-wrap: break-word;
}
.engButtonStyle {
  width: 110px;
  margin-right: 30px;
  color: #05386b;
}
.horiLineStyle {
  width: 350px;
  margin-top: 0px;
  border: 1px solid #5cdb95;
}
.sppCheckStyles {
  margin-left: 126px;
}
.smPIconStyle {
  margin-left: 60px;
}

.stmbSt {
  background-color: red;
}
.cgIconStyles {
  font-size: 20px;
  color: #05386b;
  /* background: #05386B; */
  height: 36px;
}
.stbioTextSt {
  position: static;
  left: 0%;
  right: 97.43%;
  top: 0%;
  bottom: 76.19%;

  /* ☑️ Style v0.2/Captions/Label */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  /* ☑️ Style v0.2/Neutral/01 */

  color: #3e6d9c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}
.stbioPTextSt {
  position: static;
  left: 1.54%;
  right: 1.54%;
  top: 14.29%;
  bottom: 14.29%;

  /* ☑️ Style v0.2/Forms/01 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 10px;
}
.image-upload > input {
  visibility: hidden;
  width: 0;
  height: 0;
}
.image-uploader > input {
  visibility: hidden;
  width: 0;
  height: 0;
}

.image-upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skillButtonStyling {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 1px;

  position: static;
  width: 380px;
  height: 36px;
  left: 158px;
  top: 0px;

  /* ☑️ Style v0.2/Neutral/05 */

  background-color: #f8f8fb;
  /* ☑️ Style v0.2/Neutral/04 */

  border: 1px solid #e6ebf0;
  box-sizing: border-box;
  border-radius: 7px;

  /* Inside auto layout */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #05386b;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 5px 12px;
}
.main-contents {
  margin-left: 345px;
  margin-top: 30px;
}
.headCard {
  margin-left: 345px;
  margin-top: -17px;
}
.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
.card-single {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}
.main-card {
  /* width: 760px;
  margin-left: 22px; */
  margin-bottom: 40px;
}
.user-card {
  width: 270px;
}
.main-contents {
  display: grid;
  grid-template-columns: 70% auto;
  grid-gap: 1rem;
}

.tutor-cover-photo-container {
  position: relative;
}

#tutor-cover-upload-button {
  font-size: 12px;
  padding: 4px 16px;
  margin-right: 16px;
  color: white;
  background: #05386b;
  font-weight: 400;
  border-radius: 4px;
}

#tutor-cover-upload-button:hover {
  cursor: pointer;
}

.tutor-cover-photo {
  object-fit: cover;
  width: 100%;
  height: 160px;
  border-radius: 4px;
}

.bio-edit-input {
  position: relative;
}
.bio-input-icon {
  position: absolute;
  top: 138px;
  left: 328px;
  /* color: #05386b; */
}

.tutor-cover-upload {
  padding: 8px 22.5px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.tutor-profile-upload {
  margin-left: 22.5px;
  position: absolute;
  bottom: -50px;
  left: 0;
}

.tutor-profile-photo {
  object-fit: cover;
  width: 83px;
  height: 80px;
  border-radius: 50%;
  border-radius: 1px solid #e6ebf0;
}
@media screen and (max-width: 440px) {
  .tutor-profile-photo {
    width: 70px;
    height: 70px;
  }
}

.setImageHei {
  height: 170px;
  background: #f8f8fb;
}
@media screen and (max-width: 1200px) {
  .startSidebar {
    width: 360px;
  }
  .main-contents {
    display: block;
  }
  .headCard {
    margin-left: 350px;
    /* margin-top: 920px; */
  }
  .main-card {
    /* width:780px; */
    /* margin-right: 50px; */
  }
  .user-card {
    /* width:780px; */
    margin-left: 20px;
  }
  /* .sidebar .sidebar-brand ,
  .sidebar span {
    padding-left: 1rem;
    text-align: center;
  } */
  /* .main-contents {
    margin-left: 0px;
    margin-top: 660px;

  }
  .main-card {
    width:360px;

  } */
}

@media screen and (max-width: 968px) {
  .startSidebar {
    width: 360px;
  }
  .main-contents {
    display: block;
  }
  .headCard {
    margin-left: 350px;
    /* margin-top: 920px; */
  }
  .main-card {
    /* width:480px; */
    margin-right: 50px;
  }
  .user-card {
    width: 480px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  /* .sidebar {
    width: 100px;

  } */
  .startSidebar {
    margin-left: 12px;
    width: 400px;
  }
  .recent-grid {
    grid-template-columns: 100%;
  }
  .main-contents {
    /* margin-left: -32px; */
    /* margin-top: 780px; */
  }
  .warnTextStyling {
    width: 20px;
  }
  .main-card {
    width: 355px;
    /* margin-left: 12px; */
    margin-left: 15px;
  }
  .headCard {
    margin-left: 0px;
    margin-top: 920px;
    width: 402px;
    padding: 10px;
  }
  .user-card {
    width: 360px;
    margin-left: 0px;
  }
  /* .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  } */

  /* .sidebar a {
    float: left;
  } */

  /* div.content {
    margin-left: 0;
  } */
}

@media screen and (max-width: 400px) {
  /* .sidebar a {
    text-align: center;
    float: none;
  } */
  .startSidebar {
    width: 352px;
  }
  .warnTextStyling {
    width: 20px;
  }
  .main-contents {
    margin-left: -15px;
    /* margin-top: 660px; */
    /* margin-right: 10px; */
  }
  .user-card {
    width: 352px;
    margin-left: 15px;
  }
  .headCard {
    width: 353px;
  }
  .main-card {
    width: 352px;
    /* margin-left: -50px; */
  }
}
