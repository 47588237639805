@import "../../styles.scss";

.learner-dash-sidebar {
  // height: 830px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.0314);
  border-radius: 4px;
  background-color: #ffffff;

  &-calendar {
    height: max-content;
    border-bottom: 2px solid #f8f8fb;
    font-size: 12px;

    .btn-primary {
      background: transparent;
      border: transparent;
    }

    .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
      margin-top: 16px;
    }

    .fc .fc-toolbar-title {
      font-size: 15px;
    }
  }
  &-events {
    max-height: 440px;
    overflow-y: auto;
    margin-right: 16px;
    margin-left: 26px;

    @include respond-below(sm) {
      height: 100%;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px transparent;
      -webkit-box-shadow: inset 0 0 6px transparent;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e6ebf0;
      border: 2px solid transparent;
    }
  }
  &-currentdate {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  }
  &-schedule {
    height: 28px;
    width: 28px;
    background-color: #f8f8fb;
    position: relative;
    border-radius: 3px;
  }
  &-icon {
    font-size: 20px;
    color: #05386b;
    position: absolute;
    top: 4px;
    left: 5px;
  }
  &-date {
    font-size: 14px;
    color: #05386b;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
    margin-left: 5px;
  }

  &-user {
    border-radius: 4px;
    border: 1px solid #e6ebf0;
    background: #f8f8fb;
    margin-right: 24px;
    padding: 12px;

    @include respond-below(md) {
      // margin-left: 10px;
      margin-right: 10px;
    }
  }
  &-profile {
    display: flex;
    width: 100%;
    // padding: 12px;
  }
  &-image {
    height: 42px;
    width: 42px;
    border-radius: 40px;
  }
  &-subject {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #05386b;
    margin-top: -3px;
  }
  &-name {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8ca6c0;
    margin-top: -7px;
  }
  &-brifcontent {
    margin-top: -2px;
    // margin-left: 16px;
  }
  &-previous {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #05386b;
    text-decoration: underline;
  }
  &-brif {
    font-size: 14px;
    font-weight: 450;
    line-height: 24px;
    color: #3e6d9c;
  }
  &-reschedule {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    &_icon {
      font-size: 16px;
      color: #48b3e0;
      font-weight: 500;
      margin-right: 10px;
      margin-top: 3px;
    }
    &_head {
      font-size: 13px;
      color: #48b3e0;
      font-weight: 500;
      line-height: 19.5px;
    }
  }
  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    @include respond-below(lg) {
      flex-direction: column;
    }

    @include respond-below(md) {
      flex-direction: row;
    }
  }
  &-btn1.btn,
  &-btn2.btn {
    padding: 0.5rem 2.5rem;
    margin-top: 8px;

    @include respond-below(xl) {
      padding: 0.5rem 2rem;
    }

    @include respond-below(md) {
      padding: 0.5rem 2.5rem;
    }
  }

  &-btn1.btn {
    margin-left: 10px;
  }
  &-footer {
    margin-bottom: 10px;
  }
  &-user1 {
    height: 68px;
    border-radius: 4px;
    border: 1px solid #e6ebf0;
    background: #f8f8fb;
    margin-right: 16px;

    @include respond-below(sm) {
      width: 318px;
    }
  }
  &-profile1 {
    display: flex;
    width: 100%;
    padding: 12px;
  }
  &-image1 {
    height: 42px;
    width: 42px;
    border-radius: 40px;
  }
  &-subject1 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #05386b;
    margin-top: -3px;
  }
  &-name1 {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #8ca6c0;
    margin-top: -7px;
  }

  &-user2 {
    border-radius: 2px;
    background: #ecf9f3;
    margin-top: 10px;
  }
  &-profiles {
    display: flex;
    flex-direction: row;
    margin-left: 12px;
    margin-right: 8px;
  }
  &-image2 {
    height: 42px;
    width: 42px;
    border-radius: 40px;
    margin-top: 12px;
  }
  &-subject2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #8ca6c0;
    margin-top: 11px;
  }
  &-name2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #05386b;
    margin-top: -10px;
  }

  .learner-dash-sidebar-currenttime {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: #8ca6c0;
    margin-top: 10px;
    margin-bottom: 4px;
    letter-spacing: 0.3px;
  }
  .learner-dash-sidebar-explore {
    background: $secondary-color-1;
    padding-left: 18px;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    margin-top: 20px;

    .learner-dash-sidebar-explore-background {
      position: absolute;
      bottom: 130px;
      left: 145px;
      z-index: 10;
      overflow: hidden;
    }

    .learner-dash-sidebar-eclipse-user-container {
      margin-top: 36.2px;
      margin-left: 62px;
    }

    .learner-dash-sidebar-explore-line-1 {
      color: $secondary-color-1;
      width: 52.46px;
    }
    .learner-dash-sidebar-explore-line-2 {
      color: $secondary-color-1;
      width: 52.46px;
    }
    .learner-dash-sidebar-explore-img-container {
      background: $white-color;
      border-radius: 18px;
      width: 132.46px;

      .learner-dash-sidebar-explore-head-img {
        width: 32.86px;
        height: 32.94px;
        border: 1px solid $primary-color;
        padding-right: 8px;
      }
    }

    .learner-dash-sidebar-explore-head-img {
      border-radius: 50%;
    }

    .learner-dash-sidebar-explore-text {
      color: $white-color;
      font-size: 24px;
      padding-bottom: 43px;

      strong {
        color: $primary-color;
      }
    }

    .learner-dash-sidebar-explore-offer {
      background: $primary-color;
      color: $white-color;
      font-size: 14px;
      padding: 4px 10px;
      width: 98px;
      border-radius: 11px;
      text-align: center;

      strong {
        line-height: 14px;
      }
    }
  }
}

////----Profile connect section---//
.card {
  &-connect {
    min-height: 372px;
    max-width: 380px;
    color: #ffffff;
    background-color: #3e6d9c;
    border-radius: 4px;
    box-shadow: 1px 14px 0px -4px #b8c7d6;

    &_content {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &_box {
      position: relative;
      height: 39.7px;
      width: 132.4px;
      border-radius: 30px;
      background-color: #ffffff;
      margin-top: 10px;
      margin-left: 30px;
      box-shadow: 4px 6px 0px rgba(0, 0, 0, 0.27);
      z-index: 11;
    }
    &_box1 {
      position: relative;
      height: 39.7px;
      width: 132.4px;
      border-radius: 30px;
      background-color: #ffffff;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 90px;
      z-index: 11;
    }
    &_box2 {
      position: relative;
      height: 39.7px;
      width: 132.4px;
      border-radius: 30px;
      margin-left: 30px;
      background-color: #ffffff;
      z-index: 11;
    }
    &_line {
      height: 3.7px;
      width: 52.4px;
      border-radius: 18px;
      background-color: #05386b;
      margin-top: 15px;
    }
    &_line1 {
      height: 3.7px;
      width: 76.6px;
      border-radius: 18px;
      background-color: #b8c7d6;
    }

    &_circle {
      height: 32px;
      width: 32px;
      border-radius: 30px;
      background-color: #f8f8fb;
      border: 1px solid #5cdb95;
      margin: 4px 4px;
    }

    &-total {
      height: 44px;
      width: 98px;
      border-radius: 11px;
      background-color: #5cdb95;
      margin-top: 30px;
      position: relative;
    }
    &_head {
      color: #ffffff;
      width: 180px;
      font-size: 24px;
      line-height: 38px;
      font-weight: 600;
      position: absolute;
      top: 5px;
      left: 5px;
    }

    &_head1 {
      width: 100%;
      color: #ffffff;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.2px;
      font-weight: 600;
      margin-top: 7px;
    }
    &_span {
      color: #5cdb95;
      font-weight: 600;
    }
  }
}
