.overflow-handler {
  // Styles for firefox
  scrollbar-width: thin;
  scrollbar-color: rgb(92, 219, 149) white;

  // Styles for others
  &::-webkit-scrollbar {
    width: 8px;
    background-color: rgb(92, 219, 149);
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
    box-shadow: inset 0 0 5px #45bba6;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(92, 219, 149);

    &:hover {
      background-color: rgb(92, 219, 149);
    }
  }
}
