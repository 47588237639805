.zf_lB_Dimmer_165012 {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgb(0, 0, 0);
  opacity: 0.8;
  z-index: 10000000;
}

.zf_lB_Container_165012 {
  position: fixed;
  background-color: white;
  margin: 0;
  margin-right: 0px;
  padding: 0;
  height: 500px;
  width: 850px;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: none;
  max-height: calc(100% - 10px);
  z-index: 999999;
}

p {
  margin-bottom: 10px;
}

.zf_lB_Wrapper_165012 {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: 0;
  margin-top: -180px;
  z-index: 10000001;
}

.zf_main_id_165012 {
  height: calc(100% - 0px);
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
}

.zf_lb_closeform_165012 {
  position: absolute;
  right: -20px;
  background: #000;
  padding: 0;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  top: -15px;
  cursor: pointer;
  border: 2px solid #d9d9d9;
}
.zf_lb_closeform_165012:before,
.zf_lb_closeform_165012:after {
  position: absolute;
  left: 14px;
  content: " ";
  height: 19px;
  width: 2.5px;
  top: 5px;
  background-color: #f7f7f7;
}
.zf_lb_closeform_165012:before {
  transform: rotate(45deg);
}
.zf_lb_closeform_165012:after {
  transform: rotate(-45deg);
}

@media only screen and (min-width: 700px) and (max-width: 800px) {
  .zf_lB_Container_165012 {
    width: 650px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 698px) {
  .zf_lB_Container_165012 {
    width: 500px;
  }
}
@media only screen and (min-width: 350px) and (max-width: 549px) {
  .zf_lB_Container_165012 {
    width: 350px;
  }
}

.backgroundBg {
  background-color: transparent !important;
}
