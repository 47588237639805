.e-appointment {

  &-details {
    padding: 0 !important;
  }
}
.template-wrapper {
  padding: 0 3px;

  .subject {
    color: #05386b;
    font-size: 13px;
    font-weight: 500;
  }
  .time-container {
    .time-box {
      color: #3e6d9c;
      background-color: white;
      padding: 5px;
      border-radius: 4px;
      display: inline-block;
    }
  }
  .tutor-name {
    color: #3e6d9c;
  }
}
.event-icon-box {
  position: absolute;
  bottom: 3.5px;
  right: 3.5px;
}

.session-type-icon-box {
  width: 22px;
  height: 22px;
  padding: 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: white;
}

.session-type-icon-box-sm {
  width: 14px;
  height: 14px;
  padding: 1px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  color: white;
}

// ==============================================================================
/* custom code start*/

#EventLog b {
  color: #388e3c;
}

hr {
  margin: 1px 10px 1px 0px;
  border-top: 1px solid #eee;
}

.year-property-panel td {
  padding-bottom: 1rem;
}

.e-schedule .e-schedule-table > tbody > tr > th,
.e-schedule .e-schedule-table > tbody > tr > td {
  white-space: break-spaces;
}

/* custom code end*/

.e-schedule .e-schedule-toolbar .e-icon-schedule-print::before {
  content: "\e973";
}

.e-schedule .e-schedule-toolbar .e-icon-schedule-pdf::before {
  content: "\e7c8";
}

.e-schedule .e-schedule-toolbar {
  background-color: #f8f8fb;
  margin-bottom: 0px;
  border-bottom: 1px solid #dee2e6;
}

.custom-field-row {
  margin-bottom: 20px;
}

.multi-prop div {
  padding-left: 0;
  padding-top: 0;
}

.year-view.e-schedule .e-timeline-year-view .e-resource-column-table,
.year-view.e-schedule .e-timeline-year-view .e-resource-left-td {
  width: 120px;
}

.inline-edit.e-schedule .e-timeline-view .e-resource-left-td,
.inline-edit.e-schedule .e-timeline-month-view .e-resource-left-td {
  width: 150px;
}

.e-subject {
  color: #05386b;
  font-size: 13px;
  font-weight: 500;
}

.e-time {
  color: #3e6d9c;
  font-size: 12px;
  background-color: #ffffff;
  padding: 3px;
  margin-top: 2px;
  font-weight: 500;
}

.e-location {
  color: #3e6d9c;
  font-size: 12px;
  font-weight: 500;
}

.e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
  background-color: rgba(92, 219, 149, 0.1);
  border: 0px;
}

.e-event-popup .e-schedule .e-vertical-view .e-day-wrapper .e-appointment {
  border-left: 2px solid rgb(92, 219, 149) !important;
}

.e-quick-popup-wrapper
  .e-event-popup
  .e-popup-header
  .e-subject-wrap
  .e-subject {
  border-left-color: rgb(92, 219, 149);
}

.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
  background-color: rgb(92, 219, 149);
  border-color: #5cdb95;
  color: white;
}

.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]):hover {
  background-color: rgb(92, 219, 149);
  border-color: #5cdb95;
  color: white;
}

.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]):focus {
  background-color: rgb(92, 219, 149);
  border-color: #5cdb95;
  color: white;
}

.e-toolbar .e-tbar-btn {
  color: #05386b !important;
}

.e-toolbar .e-tbar-btn:hover {
  background-color: #f8f8fb;
  color: #05386b !important;
}

.e-toolbar .e-tbar-btn:focus {
  background-color: #f8f8fb;
  color: #05386b !important;
}

.e-toolbar .e-tbar-btn:hover .e-icons,
.e-toolbar .e-tbar-btn:hover .e-tbar-btn-text {
  color: #3e6d9c !important;
}

.e-toolbar .e-tbar-btn:focus .e-icons,
.e-toolbar .e-tbar-btn:focus .e-tbar-btn-text {
  color: #3e6d9c !important;
}

.e-schedule .e-vertical-view .e-work-cells {
  background-color: white;
}

.e-toolbar-right {
  border: 1px solid #e6ebf0;
  border-radius: 4px;
  margin: 5px;
  margin-right: 25px;
  height: 38px !important;
}

.e-btn-icon {
  color: #05386b !important;
}

.e-btn.e-flat {
  background-color: #05386b !important;
  color: #ffffff !important;
}

.e-quick-popup-wrapper .e-cell-popup .e-date-time-details,
.e-quick-popup-wrapper .e-cell-popup .e-location-details,
.e-quick-popup-wrapper .e-cell-popup .e-time-zone-details,
.e-quick-popup-wrapper .e-cell-popup .e-description-details,
.e-quick-popup-wrapper .e-cell-popup .e-resource-details,
.e-quick-popup-wrapper .e-event-popup .e-date-time-details,
.e-quick-popup-wrapper .e-event-popup .e-location-details,
.e-quick-popup-wrapper .e-event-popup .e-time-zone-details,
.e-quick-popup-wrapper .e-event-popup .e-description-details,
.e-quick-popup-wrapper .e-event-popup .e-resource-details {
  color: #3e6d9c !important;
}
.e-quick-popup-wrapper .e-event-popup .e-date-time-icon {
  color: #3e6d9c !important;
}

.e-quick-popup-wrapper .e-event-popup .e-location-icon {
  color: #3e6d9c !important;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items.e-tbar-pos {
  height: 50px;
  min-height: 38px;
}

.e-active-view,
.e-active-view button {
  background-color: #e6ebf0 !important;
}

.e-active-view button span {
  color: #05386b !important;
  font-weight: 500;
}

.e-schedule .e-vertical-view .e-work-cells {
  border-color: #e6ebf0;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td {
  background-color: #f8f8fb;
  color: #8ca6c0;
  font-size: 14px;
}

.e-schedule .e-vertical-view .e-header-cells .e-header-date {
  color: #8ca6c0;
  font-weight: 600;
}

.e-header-cells {
  background-color: #f8f8fb !important;
  border-width: 0px 0px 0px 1px;
}

.e-schedule .e-vertical-view .e-header-cells .e-header-day {
  font-size: 14px;
  padding-bottom: 0px;
  margin-top: 2px;
  color: #8ca6c0;
}

.e-header-cells div {
  width: 50%;
  float: left;
  text-align: center;
}

.e-schedule .e-month-view .e-appointment .e-appointment-details .e-subject {
  padding: 3px 4px;
}

.e-quick-popup-wrapper
  .e-event-popup
  .e-popup-header
  .e-subject-wrap
  .e-subject {
  font-size: 16px;
  background-color: #ffffff;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  background-color: #5cdb95 !important;
  color: #ffffff;
}

.e-btn.e-flat.e-primary:focus {
  background-color: #5cdb95;
  color: #ffffff;
}

.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
  background-color: #5cdb95;
  border: 1px solid #5cdb95;
  color: #ffffff;
}

.e-quick-popup-wrapper .e-cell-popup .e-header-icon-wrapper .e-edit,
.e-quick-popup-wrapper .e-cell-popup .e-header-icon-wrapper .e-delete,
.e-quick-popup-wrapper .e-cell-popup .e-header-icon-wrapper .e-close,
.e-quick-popup-wrapper .e-event-popup .e-header-icon-wrapper .e-edit,
.e-quick-popup-wrapper .e-event-popup .e-header-icon-wrapper .e-delete,
.e-quick-popup-wrapper .e-event-popup .e-header-icon-wrapper .e-close {
  background-color: transparent !important;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  background-color: transparent !important;
}

.e-calendar .e-btn.e-today.e-flat.e-primary,
.e-calendar .e-css.e-btn.e-today.e-flat.e-primary {
  border: 0px;
}

.e-header-cells span {
  color: #8ca6c0;
}
/* .e-toolbar-right .e-toolbar-item:first-child {
  display: none;
}

.e-toolbar-right .e-toolbar-item:nth-child(2) {
  display: none;
} */
