:root {
  --onboarding-progress-mobile-width: 48px;
  --onboarding-progress-mobile-z-index: 10;
}

.onboarding-progress-mobile {
  width: var(--onboarding-progress-mobile-width);
  z-index: var(--onboarding-progress-mobile-z-index);
  padding: var(--onboarding-progress-mobile-width) 0;
}

.tutor-profile-setup {
  padding-left: calc(1.15 * var(--onboarding-progress-mobile-width));

  @media (min-width: 992px) {
    padding-left: 0;
  }
}
