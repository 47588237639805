.refer-a-friend {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 32px 24px;
  margin-top: 2rem;
  overflow: hidden;

  .refer-a-friend-button {
    font-size: 13px;
    font-weight: 400;
  }

  .refer-a-friend-img {
    border-radius: 50%;
    width: 72px;
    height: 72px;
    object-fit: cover;
    margin-bottom: 8px;
  }

  .refer-a-friend-header {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #05386b;
    margin-bottom: 8px;
  }

  .refer-a-friend-subheading {
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
    color: #3e6d9c;
    margin-bottom: 24px;
  }
}
