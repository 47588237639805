@use "../../assets/scss/variables" as vars;

// Credit Card.
@mixin text {
  color: white;
  position: absolute;
}

.container {
  aspect-ratio: 14/9;
}

.brand {
  @include text();
  top: 17.5%;
  left: 7.5%;
  font-size: 1rem;
  font-weight: bold;
}

.holder {
  @include text();
  bottom: 15%;
  left: 7.25%;
  font-weight: bold;

  @media (min-width: 992px) {
    font-size: 0.65rem;
  }

  @media (min-width: 1200px) {
    font-size: 1rem;
  }
}

.expiry {
  @include text();
  bottom: 15%;
  right: 11%;

  @media (min-width: 992px) {
    font-size: 0.65rem;
  }

  @media (min-width: 1200px) {
    font-size: 1rem;
  }
}

.number {
  @include text();
  top: 35%;
  right: 21%;
  font-weight: bold;
  font-size: 1.25rem;

  @media (min-width: 375px) {
    font-size: 1.5rem;
  }

  @media (min-width: 992px) {
    font-size: 1rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.5rem;
  }
}
