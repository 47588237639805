@import "../../assets/scss/mixins";

.date-picker {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(3, 1fr);

  @include respond-below(xxs) {
    grid-template-columns: 1fr 1fr;
  }
}

.day-picker {
}

.month-picker {
  @include respond-below(xxs) {
    grid-column: 1/-1;
    grid-row: 2/3;
  }
}

.year-picker {
  @include respond-below(xxs) {
    grid-column: 2/-1;
    grid-row: 1/2;
  }
}
