/// A place for all the custom utility classes.

// General utils.
.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.bg {
  &-blue {
    background-color: #3e6d9c;
  }
}

// Blue colors start here.
@each $color-name,
  $color-value
    in (
      100: $blue-100,
      200: $blue-200,
      300: $blue-300,
      400: $blue-400,
      500: $blue-500,
      600: $blue-600,
      700: $blue-700,
      800: $blue-800,
      900: $blue-900
    )
{
  .text-blue-#{$color-name} {
    color: $color-value !important;
  }

  .bg-blue-#{$color-name} {
    background-color: $color-value !important;
  }
}
// Blue colors end here.

@each $key, $value in $grays {
  .text-gray-#{$key} {
    color: $value !important;
  }

  .bg-gray-#{$key} {
    background-color: $value !important;
  }

  .hover\:bg-gray-#{$key}:is(:hover) {
    background-color: $value !important;
  }

  .focus\:bg-gray-#{$key}:is(:focus) {
    background-color: $value !important;
  }
}

.text {
  &-blue {
    color: #3e6d9c !important;
  }

  &-blue-300 {
    color: #8ca6c0;
  }
}

.blur {
  @each $number in (1, 2, 3, 4, 5) {
    &-#{$number} {
      filter: blur(#{$number}px);
    }

    &-bg-#{$number} {
      backdrop-filter: blur(#{$number}px);
    }
  }
}

.z {
  @each $key, $value in (0: 0, 1: 10, 2: 20, 3: 30, 4: 40, 5: 50) {
    &-#{$key} {
      z-index: $value !important;
    }

    // Negative indexes.
    &-n#{$key} {
      z-index: -$value !important;
    }
  }
}

// Breakpoint-related shit.
@each $breakpoint-name, $breakpoint-value in $grid-breakpoints {
  @each $position in (static, relative, absolute) {
    @media (min-width: $breakpoint-value) {
      .position-#{$breakpoint-name} {
        &-#{$position} {
          position: $position !important;
        }
      }
    }
  }

  @each $number in (25, 50, 75, 100, 200, 300) {
    // w-md
    .w-#{$breakpoint-name} {
      @include size-utils($breakpoint-value, $number, "width");
    }

    // h-md
    .h-#{$breakpoint-name} {
      @include size-utils($breakpoint-value, $number, "height");
    }

    .vh-#{$breakpoint-name} {
      @include size-utils-viewport($breakpoint-value, $number, "height");
    }
  }

  .h-#{$breakpoint-name} {
    height: $breakpoint-value !important;
  }

  .minw-#{$breakpoint-name} {
    min-width: $breakpoint-value !important;
  }

  .maxw-#{$breakpoint-name} {
    max-width: $breakpoint-value !important;
  }

  .minh-#{$breakpoint-name} {
    min-height: $breakpoint-value !important;
  }

  .maxh-#{$breakpoint-name} {
    max-height: $breakpoint-value !important;
  }

  .border {
    @each $color-shade, $color-value in $grays {
      &-gray-#{$color-shade} {
        border-color: $color-value !important;
      }
    }

    @each $direction in (top, right, bottom, left) {
      @each $number in (0, 1, 2, 3, 4, 5) {
        // Generates classes such as .border-right-0
        &-#{$direction}-#{$number} {
          @media (min-width: $breakpoint-value) {
            border-#{$direction}-style: solid;
            border-#{$direction}-width: #{$number}px;
          }
        }

        // Generates classes such as .border-right-sm-0
        &-#{$direction}-#{$breakpoint-name}-#{$number} {
          @media (min-width: $breakpoint-value) {
            border-#{$direction}-style: solid !important;
            border-#{$direction}-width: #{$number}px !important;
          }
        }
      }
    }
  }
}

// Fonts.
.fs {
  &-5\.5 {
    font-size: 0.9rem !important;
  }

  &-7 {
    font-size: 0.75rem !important;
  }

  &-8 {
    font-size: 0.65rem !important;
  }
}

.fw {
  &-semi-bold {
    font-weight: 500 !important;
  }
}

.object {
  &-contain {
    object-fit: contain !important;
  }

  &-cover {
    object-fit: cover !important;
  }

  @each $direction in (top, right, bottom, left, center) {
    &-#{$direction} {
      object-position: $direction;
    }
  }
}

.rotate {
  @each $angle in (0, 90, 180, 270) {
    &-#{$angle} {
      transform: rotate(#{$angle}deg);
    }
  }
}

@mixin text-truncate($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines; /* number of lines to show */
  line-clamp: $lines;
  -webkit-box-orient: vertical;
}

// Fixed width & height.
@each $value in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10) {
  .w-#{$value} {
    width: #{$value}rem;
  }

  .minw-#{$value} {
    min-width: #{$value}rem;
  }

  .maxw-#{$value} {
    max-width: #{$value}rem;
  }

  .h-#{$value} {
    height: #{$value}rem;
  }

  .minh-#{$value} {
    min-height: #{$value}rem;
  }

  .maxh-#{$value} {
    max-height: #{$value}rem;
  }

  // Truncate text after n lines.
  .text-truncate-n#{$value} {
    @include text-truncate($value);
  }
}

.overflow-y-auto {
  overflow-y: auto !important;
  overflow-x: hidden;
}
.overflow-x-auto {
  overflow-x: auto !important;
  overflow-y: hidden;
}

.list-default {
  margin: 0;
  padding-left: 1.5rem;
  list-style-type: disc;

  li {
    list-style-type: disc;
  }
}

// Opacity.
$opacity: (
  0: 0,
  25: 0.15,
  50: 0.5,
  75: 0.75,
  100: 1,
);

@each $opacity-key, $opacity-value in $opacity {
  .opacity-#{$opacity-key} {
    opacity: $opacity-value !important;
  }
}

.divider {
  border-right: 1px solid $gray-400 !important;
}