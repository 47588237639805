.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent !important;
}

.loader-inline {
  @compose loader;
  height: auto;
  text-align: center;
}
