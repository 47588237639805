// Thumbnails.
@mixin thumbnail-wrapper {
  overflow: hidden;
  position: relative;
  min-height: $thumbnail-height;
  height: 100%;
}

.thumbnail-wrapper {
  @include thumbnail-wrapper();

  &.h-2 {
    min-height: calc(#{$thumbnail-height} + 2rem);
  }

  &.h-3 {
    min-height: calc(#{$thumbnail-height} + 3rem);
  }

  &.h-4 {
    min-height: calc(#{$thumbnail-height} + 4rem);
  }

  &.h-5 {
    min-height: calc(#{$thumbnail-height} + 5rem);
  }
}

.thumbnail {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
