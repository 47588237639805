@import "assets/scss/styles.scss";

.tutor-dashboard-padding {
  padding: 0 24px;
}

.tutor-profile-setup-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #3e6d9c;
}
.edit-skills-button {
  font-size: 13px !important;
  padding: 8px 16px !important;
  background-color: #05386b !important;
  font-weight: 400 !important;
  margin-left: 10px;
  @include respond-below(lg) {
    margin-left: 0px;
    padding: 12px 16px !important;
  }
  @include respond-below(lg) {
    margin-left: 10px;
  }
  @include respond-below(md) {
    margin-left: 5px;
  }
  @include respond-below(sm) {
    margin-left: 0px;
  }
}
.tutor-dashboard-header {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #05386b;
}
.profile {
  &-content {
    border-bottom: 1px solid #e6ebf0;
    border-top: 1px solid #e6ebf0;
    position: relative;
    margin-left: 3px !important;
    overflow: hidden;
    background-color: #f8f8fb;

    .active {
      border-bottom: 2.5px solid $secondary-color;
    }
  }

  &-routes {
    padding-top: 16px;
    color: $secondary-color-3;

    &_title {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: $secondary-color-3;
      margin-right: 2px;
    }
    &_icon {
    }
    &_span {
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: $secondary-color;
    }
  }

  &-head {
    margin-top: 24px;
  }

  &-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: $secondary-color;
  }

  &-image {
    position: relative;
  }
  &-img {
    position: absolute;
    top: 5px;
    right: 37px;
    height: 147px;
    width: 200px;

    @include respond-below(sm) {
      top: 35px;
      right: 16px;
      height: 80px;
    }
    @include respond-below(md) {
      top: 55px;
      right: 10px;
      height: 115px;
    }
    @include respond-below(lg) {
      top: 55px;
      right: 10px;
      height: 115px;
    }
    @include respond-below(xl) {
      top: 45px;
      right: 10px;
      height: 115px;
    }
    @include respond-below(xs) {
      top: 58px;
      right: 10px;
      height: 85px;
    }
  }
}

// Profile headers ////

.tutor-tabs {
  border-radius: 4px;
  margin-top: 32px;
  margin-left: -18px;
  margin-bottom: 15px;

  @include respond-below(sm) {
    margin-bottom: 6px;
  }

  &-header1 {
    &_task {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: $secondary-color-1;
      padding: 0 20px;
      padding-bottom: 12px;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

      @include respond-below(sm) {
        font-size: 12px;
        margin-top: 5px;
        margin-right: 5px;
        margin-left: 10px;
        line-height: 18px;
        padding: 4px;
        padding-bottom: 4px;
        width: max-content;
      }
      @include respond-below(xs) {
        font-size: 14px;
        margin-top: 8px;
        margin-right: 8px;
        margin-left: 5px;
        padding: 2px;
        padding-bottom: 4px;
      }
    }
  }
}
.primary-color-button-fix {
  background-color: #05386b !important;
  color: white;
}
// .active {
//   color: $secondary-color;
//   border-bottom: 3px solid $secondary-color;
// }

//--Basic Information --//
.basic {
  background-color: $white-color;
  // height: 800px;
  width: 100%;
}
.basic-image {
  margin-left: 12px;
}
.basic-img {
  margin-top: 20px;
  @include respond-below(sm) {
    height: 100%;
    width: 340px;
  }
}

.basic-info-photo-container {
  position: relative;
}

.basic-info-cover-photo {
  object-fit: cover;
  width: 100%;
  height: 238px;
  border-radius: 8px;
}
.basic-info-edit-cover-photo {
  object-fit: cover;
  width: 100%;
  height: 160px;
  border-radius: 8px;
}
#basic-cover-upload-button {
  font-size: 12px;
  padding: 8px 16px;
  margin-right: 16px;
  color: white;
  background: #05386b;
  font-weight: 400;
  border-radius: 4px;
}

#basic-cover-upload-button:hover {
  cursor: pointer;
}

.basic-info-cover-upload {
  padding: 8px 22.5px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.tutor-profile-upload {
  margin-left: 22.5px;
  position: absolute;
  bottom: -50px;
  left: 0;
  @media screen and (max-width: 440px) {
    margin-left: 2.5px;
    bottom: -43px;
  }
}

.basic-info-profile-upload {
  margin-left: 22.5px;
  position: absolute;
  bottom: -65px;
  left: 0;
}
.basic-info-edit-profile-upload {
  margin-left: 22.5px;
  position: absolute;
  bottom: -55px;
  left: 0;
}

.basic-info-profile-photo {
  object-fit: cover;
  width: 138px;
  height: 138px;
  border-radius: 50%;
  margin-left: 20px;
  // margin-top: 50px;
  border-radius: 1px solid #e6ebf0;

  @include respond-below(xs) {
    width: 100px;
    height: 100px;
    margin-left: 0;
  }
}
.profile-verify {
  margin-left: 10px;
  @include respond-below(sm) {
  }
}

.basic-designer {
  @include respond-below(sm) {
    margin-left: 50px;
    // margin-top: 50px;
    font-size: 14px;
  }
}

.basic-design-head {
  @include respond-below(sm) {
    margin-left: 50px;
    font-size: 14px;
  }
}
.basic-designer-name {
  color: $secondary-color;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.basic-profile-head {
  @include respond-below(md) {
    margin-top: 65px !important;
  }
  @include respond-below(sm) {
    margin-left: 0px;
  }
}

.basic-profile-exp {
  margin-left: 195px;
  @include respond-below(sm) {
    margin-left: 0px;
    // margin-bottom: 20px;
  }
  @include respond-below(xs) {
    margin-left: 0px;
    // margin-bottom: 20px;
  }
}
.basic-designer-exp {
  color: $secondary-color-1;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 0px;
  width: max-content;
}
.basic-designer-exp-img {
  margin: 0 5px;
}

.basic-button {
  display: flex;
  flex-direction: row;
  justify-content: end;
  @include respond-below(xs) {
    margin-top: 10px;
    justify-content: start;
    margin-left: 35px;
  }
}
.basic-button-view {
  color: $secondary-color;
  width: max-content;
  border: 2px solid #05386b;
  margin-right: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 42px;
}
.basic-button-user {
  color: $secondary-color;
  font-size: 12px;
  margin-left: 8px;
  width: max-content;
}
.basic-button-edit {
  font-size: 12px;
  width: max-content;
  height: 42px;
  @include respond-below(sm) {
  }
}
.basic-button-title {
  color: $white-color;
  font-size: 12px;
  margin-left: 10px;
  width: max-content;
}

.basic-user-content {
  margin-left: 195px;
  padding: auto 0;

  @include respond-below(sm) {
    margin-left: 50px;
    margin-top: 40px;
    font-size: 10px;
  }
  @include respond-below(xs) {
    margin-left: 40px;
    margin-top: 40px;
    font-size: 10px;
  }
}

.cover-image-input {
  background-color: #05386b;
  color: $white-color;
}
.basic-user-detail-main {
  // display: flex;
  // flex-direction: row;
  // @include respond-below(xs) {
  //   flex-direction: column;
  // }
}
.basic-user-detail {
  color: $secondary-color-1;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 14px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
}
.basic-detail {
  color: $secondary-color-1;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 12px;
}
.basic-detail-time {
  color: $secondary-color-1;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  flex-direction: row;

  // @include respond-below(sm) {
  //   margin-left: 20px;
  // }
}
.basic-timezone {
  width: max-content;
}

.basic-mobile {
  width: max-content;

  @include respond-below(md) {
    width: max-content;
  }
}
.basic-icon {
  margin-top: 2px;
  margin-right: 4px;
  font-size: 18px;
}
.basic-description {
  margin-left: 200px;
  @include respond-below(sm) {
    margin-left: 50px;
  }
  @include respond-below(xs) {
    margin-left: 40px;
  }
}
.basic-desc {
  color: $secondary-color;
  margin-top: 40px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.basic-para {
  color: $secondary-color-1;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  // padding-bottom: 8px;
  line-height: 2;
}

//----Qualification Section-----////

.qualify-content {
  // height: 600px;
  width: 100%;
  background-color: $white-color;
  position: relative;
}

.qualify-duration {
  font-size: 13px;
  border-bottom: 1px solid $secondary-color-5;
  margin-left: 13px;
}

.qualify-head {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-color-1;
}
.qualify-course {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-color;
}
.qualify-college {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-color-3;
}
.qualify-button-edit {
  // float: right;
  margin-top: 24px;
  font-size: 12px;
}
.qualify-button-title {
  color: $white-color;
  font-size: 12px;
  margin-left: 10px;
}

//---Experience section---//

.experience-content {
  height: 100%;
  width: 100%;
  background-color: $white-color;
  position: relative;
}
.edit-skills1 {
  height: 100%;
  width: 100%;
  background-color: $white-color;
  position: relative;
}
.experience-duration {
  font-size: 13px;
  // margin-left: 13px;
}
.experience-back {
  border-bottom: 2px solid $secondary-color-5;
}
.experience-head {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-color-1;
}
.experience-course {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-color;
  margin: 2px 6px;

  @include respond-below(sm) {
    margin-left: 5px;
    margin-right: 2px;
  }
}
.experience-line {
  width: 3px;
  height: 18px;
  background-color: #e6ebf0;
  margin: 2px 6px;
  @include respond-below(sm) {
    margin: 1px;
  }
}
.experience-line1 {
  width: 3px;
  height: 18px;
  background-color: #e6ebf0;
  margin: 2px 0px;
  @include respond-below(sm) {
    margin: 1px;
  }
}
.experience-college {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: $secondary-color;
  margin: 4px 6px;
}
// .experience-btn{
//   float: right;
// }
.experience-button-edit {
  // float: right;
  margin-top: 24px;
  font-size: 12px;
}
.experience-button-title {
  color: $white-color;
  font-size: 12px;
  margin-left: 10px;
}
.experience-img {
  font-size: 12px;
  // height: 16px;
  // width: 16px;
}
.experience-main-content {
  margin-left: 18px;
}
.experience-total {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: $secondary-color-1;
  margin-left: 10px;
}
.experience-in-years {
  margin-left: 8px;
}
.experience-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $secondary-color-1;
}

//----Skill Content ---//
.skill-content {
  height: 600px;
  width: 100%;
  background-color: $white-color;
}

.skill-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-color;
}
.skill-head {
  color: $secondary-color;
  margin-left: 8px;
  font-size: 13px;
}
.skill-button {
  background-color: #f8f8fb;
  border-radius: 7px;
  padding: 8px 12px;

  @include respond-below(sm) {
    min-height: 36px;
  }
}
.skill-button-info {
  background-color: #e6ebf0;
  border-radius: 7px;
  // height: 36px;
  margin-right: 12px;
  padding: 8px 12px;

  @include respond-below(sm) {
    // height: 36px;
  }
}

.button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: #000;
  fill: #05386b;
  background-color: #05386b;
}
.off {
  color: #ccc;
  fill: #ccc;
}
.skill-icon {
  color: #f3d11a;
  margin-left: 5.3px;
  margin-right: 1px;
}
.skill-rating {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-right: 19px;
  color: $secondary-color;
}

.skill-remove {
  color: $secondary-color;
  font-size: 10px;
  margin-right: 5px;
  cursor: pointer;
}
.skill-button-edit {
  // margin-top: 24px;
  font-size: 12px;
  @include respond-below(sm) {
  }
}
.skill-button-title {
  color: $white-color;
  font-size: 12px;
  margin-left: 10px;
}

//--Subjects Section --//
.subject-content {
  height: 600px;
  width: 100%;
  background-color: $white-color;
}
.subject-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-color;
}
.subject-head {
  color: $secondary-color;
  font-size: 13px;
}

.subject-button {
  background-color: #e6ebf0;
  border-radius: 7px;
}

.subject-remove {
  color: $secondary-color;
  margin-left: 39px;
  font-size: 10px;
  cursor: pointer;
}

.subject-button-edit {
  // float: right;
  margin-top: 24px;
  font-size: 12px;
  @include respond-below(sm) {
    bottom: 325px;
    right: 10px;
  }
}
.subject-button-title {
  color: $white-color;
  font-size: 12px;
  height: 20px;
  margin-left: 10px;
}

//--Certificate Block--//

.certificate-content {
  background-color: $white-color;
  height: 100%;
  width: 100%;
}

.bootcamp {
  background-color: #f8f8fb;
  border-radius: 4px;

  @include respond-below(sm) {
  }
}
.certificate-main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
.certificate-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-color;
}
.certificate-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $secondary-color-1;
}
.certificate-issue {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $secondary-color-1;
}
.certificate-credential {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: $secondary-color-3;
}
.certificate-button-edit {
  margin-top: 24px;
  // float: right;
  font-size: 12px;
}
.certificate-button-title {
  color: $white-color;
  font-size: 12px;
  height: 20px;
  margin-left: 10px;
}

.video-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-color;
}
.video-head {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-color;
}
.video-para {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $secondary-color-1;
}

.video {
  height: 280px;

  @include respond-below(sm) {
    height: 200px;
    margin-bottom: 20px;
  }
}

///--Manage-content----

.manage {
  background-color: $white-color;
  min-height: 600px;
  width: 100%;
}

.manage-account {
  background-color: #f8f8fb;
  border-radius: 4px;
  margin-top: 10px;
}
.manage-main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}
.manage-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-color;
}
.manage-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $secondary-color-1;
}
.manage-delete {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  color: $white-color;
}

//-----Edit basic content ----//
.edit-basic {
  background-color: $white-color;
  height: 100%;
  width: 100%;
  color: $secondary-color;
}

.edit-basic-image {
  height: 160px;
  background-color: #f8f8fb;
  border-radius: 4px;
  margin-left: 10px;
  position: relative;
}

.edit-basic-upload {
  margin-top: 110px;
  margin-left: 31.6px;
}
.edit-basic-upload-file {
  margin-top: 160px;
  margin-left: 10.6px;
}

.edit-basic-cover {
  position: absolute;
  top: 60%;
  right: 26px;
}

.edit-basic-exalamation {
  color: $secondary-color;
  font-size: 20px;
  margin-left: 18.5px;
}

.basic-personal {
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 14px;
  padding-top: 37px;
  padding-bottom: 24px;
}

.basic-personal-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #05386b;
  margin-top: 22px;
  margin-bottom: 0;
}

.basic-edit-language {
  font-size: 16px;
  color: $secondary-color-1;
}
.basic-edit-choose {
  font-size: 14px;
  color: $secondary-color-1;
}
.basic-edit-suggested {
  font-size: 12px;
  color: $secondary-color-1;
}
.basic-edit-languages {
  font-size: 12px;
  color: $info;
}
.basic-edit-input {
  position: relative;
}
.basic-input-icon {
  position: absolute;
  top: 37px;
  right: 30px;
  color: #05386b;
}

//--Edit Qualify Section //
.edit-qualify {
  background-color: $white-color;
  // height: 100%;
  width: 100%;
}
.qualify-personal {
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 14px;
  // padding-top: 37px;
  padding-bottom: 24px;
}

.qualify-personal-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #05386b;
  margin-top: 22px;
  margin-bottom: 0;
}

.qualify-edit-input {
  position: relative;
}

.qualify-edit-icon {
  color: $secondary-color;
}
.qualify-border {
  height: 2px;
  background-color: #f8f8fb;
  border-radius: 2px;
}
.qualify-duration1 {
  font-size: 13px;
  border-bottom: 2px solid $secondary-color-5;
  background-color: #f8f8fb;
}
.qualify-editicon {
  background-color: $white-color;
  font-size: 13px;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  padding: 11px;
}
.qualify-delete-icon {
  font-size: 13px;
  background-color: $white-color;
  color: #ea8686;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  padding: 10px;
}

//--Edit Experience section--//
.edit-experience {
  background-color: $white-color;
  height: 100%;
  width: 100%;
}
.edit-experience-content {
  margin-left: 12px;
}
.edit-experience-content-data {
  margin-left: 24px;
}
.experience-personal {
  border-radius: 4px;
  // background-color: #ffffff;
  padding-left: 14px;
  // padding-top: 37px;
  padding-bottom: 24px;
}
.experience-personal-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #05386b;
  margin-top: 22px;
}

.experience-edit-input {
  position: relative;
}

.experience-edit-icon {
  position: absolute;
  top: 37px;
  left: 25px;
  color: $secondary-color;
}
.experience-radio {
  margin: 3px 5px;
  color: #05386b;
}

.experience-terms {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 5px;
  color: #05386b;
}

.experience-duration1 {
  font-size: 13px;
  border-bottom: 2px solid $secondary-color-5;
}
.experience-duration2 {
  font-size: 13px;
  border-bottom: 2px solid $secondary-color-5;
  background-color: #f8f8fb;
}

.experience-border {
  height: 2px;
  background-color: #f8f8fb;
  border-radius: 2px;
}
.experience-editcontent {
}

.experience-editicon {
  background-color: $white-color;
  font-size: 13px;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  padding: 11px;

  &:hover {
    cursor: pointer;
  }
}
.experience-delete-icon {
  font-size: 13px;
  background-color: $white-color;
  color: #ea8686;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  padding: 10px;
  &:hover {
    cursor: pointer;
  }
}

.edit-skills {
  background-color: $white-color;
  height: 100%;
  width: 100%;
}
.edit-skill-input-group {
  border: 1px solid #e6ebf0;
  justify-content: space-between;
  width: 100%;
  & > div:first-child {
    @include respond-below(xs) {
      border: 1px solid #e6ebf0;
    }
  }
  @include respond-below(xs) {
    border: 0px solid transparent;
  }
}
.timezone-select {
  font-size: 13px;
}
.edit-skills-input {
  // border-style: none;
  padding-left: 12px;
  outline: none;
  // border: "1px solid #484850";
  font-size: 13px !important;
}
.save-button {
  font-size: 13px !important;
  padding: 8px 16px !important;
  background-color: #05386b !important;
  font-weight: 400 !important;
  margin-left: 10px;

  @include respond-below(xs) {
    margin-left: 0px;
    padding: 12px 16px !important;
  }
  @include respond-below(md) {
    // margin-left: 25px;
  }
  @include respond-below(lg) {
    margin-left: 2px;
    padding: 12px 16px !important;
  }
}
.general-btn1 {
  margin-left: 3px;
  margin-top: 20px;
}
.edit-skills-icon {
  color: $secondary-color;
  font-size: 18px;
  @include respond-below(md) {
    margin-right: 25px;
  }
  @include respond-below(sm) {
    margin-right: 10px;
  }
  @include respond-below(lg) {
    margin-right: 0px;
  }
}

//---Edit certificates---//
.edit-certificate {
  background-color: $white-color;
  height: 100%;
  width: 100%;
}
.certificate-personal {
  border-radius: 4px;
  background-color: $white-color;
  padding-left: 14px;
  padding-bottom: 24px;
  border-bottom: 3px solid #f8f8fb;
}
.video1 {
  height: 200px;
}
.video-title1 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-color;
}
.video-info {
  @include respond-below(sm) {
    display: flex;
    flex-direction: column;
  }
  @include respond-below(xs) {
    display: flex;
    flex-direction: column;
  }
}

.video-check {
  height: 19px;
  width: 19px;
  color: $secondary-color;
  border-radius: 110px;
  background-color: #e6ebf0;
}
.video-check-content {
  color: $secondary-color-1;
  margin-left: 10px;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

//--Edit Subjects---//

.subject-edit-suggested {
  font-size: 12px;
  color: $secondary-color-1;
}
.subject-edit-languages {
  font-size: 12px;
  color: $secondary-color;
  font-weight: 400;
}

@media screen and (max-width: 480px) {
  .basic-designer,
  .basic-design-head,
  .basic-button,
  .basic-user-content,
  .basic-description {
    margin-left: 10px;
  }

  .basic-button {
    flex-basis: 100% !important;
    flex-direction: column;
    row-gap: 0.7rem;
  }
  .basic-user-content {
  }
}
