.alert {
  min-height: 6.88rem;
}

.primary {
  composes: alert;
  background-color: #f0f9fd !important;
  border: 1px solid #48b3e0 !important;
}
.success {
  composes: alert;
  background-color: #ecf9f3 !important;
  border: 1px solid #3cbd7f !important;
}

.danger {
  composes: alert;
  background-color: #fefbe8 !important;
  border: 1px solid #f3d11a !important;
}
