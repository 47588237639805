/* Tutor Main Style

Table of Contents

00.Variables and Imports
01.Base
02.Bootstrap fixes
03.Sidebars
04.Buttons
05.Common
06.React Suits fixes
07.Video Player
08.Slider fixes

/* 00.Variables and Imports */

$shadowOffsetsTop: 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom: 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./_mixins.scss";

$primary-color: #5cdb95;
$primary-color-1: #a0efc3;
$primary-color-2: #b7f1d1;
$primary-color-3: #d1f6e1;
$primary-color-4: #eefbf4;

$secondary-color: #05386b;
$secondary-color-00: #356493;
$secondary-color-1: #3e6d9c;
$secondary-color-2: #6488ac;
$secondary-color-3: #8ca6c0;
$secondary-color-4: #b8c7d6;
$secondary-color-5: #e6ebf0;
$secondary-color-6: #8a9daf;

$gray-01: #eef2f6;
$gray-1: #36414c;
$gray-3: #d5dde5;
$gray-6: #8f9ca9;
$gray-9: #272d37;
$gray-10: #182e45;

$link-color: #48b3e0;
$white-color: #ffffff;
$dark-color: #000000;
$muted-color: #8f9ca9;

$border-color: #d5dde5;
$dim-text: #b8c1cc;

$success: #3cbd7f;
$success-1: #6fd5a4;
$success-4: #c8eddb;
$success-5: #ecf9f3;

$error: #ea8686;
$error-3: #f8c7c7;
$error-5: #fdf3f3;

$info: #48b3e0;
$info-1: #7cccee;
$info-2: #9ad8f2;
$info-3: #b6e3f6;
$info-4: #d4eef9;
$info-5: #f0f9fd;

$warning: #f3d11a;

$tag-min-width: 70px;
$tutor-lead-height: 270px;

/* 01.Base */

// :root {
//   --primary-color: #{$primary-color};
//   --primary-color-1: #{$primary-color-1};
//   --primary-color-2: #{$primary-color-2};
//   --primary-color-3: #{$primary-color-3};
//   --primary-color-4: #{$primary-color-4};
//   --secondary-color: #{$secondary-color};
//   --secondary-color-1: #{$secondary-color-1};
//   --secondary-color-2: #{$secondary-color-2};
//   --secondary-color-3: #{$secondary-color-3};
//   --secondary-color-4: #{$secondary-color-4};
//   --secondary-color-5: #{$secondary-color-5};
//   --secondary-color-6: #{$secondary-color-6};

//   --dark-color: #{$dark-color};
//   --gray-1: #{$gray-1};
//   --gray-3: #{$gray-3};
//   --gray-6: #{$gray-6};
//   --gray-9: #{$gray-9};
//   --gray-10: #{$gray-10};

//   --rs-picker-value: #{$muted-color};
//   --rs-listbox-option-hover-bg: #{$primary-color-4};
//   --rs-text-primary: #{$gray-9};
//   --rs-listbox-option-hover-text: #{$gray-9};
//   --rs-listbox-option-selected-text: #{$secondary-color};
//   --rs-input-focus-border: #{$border-color};
//   --rs-listbox-option-selected-bg: #{$primary-color-4};
//   --rs-picker-value: #{$gray-6};
//   --rs-border-primary: #{$border-color};
//   --rs-text-secondary: #{$gray-6};
//   --rs-checkbox-checked-bg: #{$secondary-color};
//   --rs-btn-default-bg: #{$white-color};
// }

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #1abc9c;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --dark-color: #{$dark-color};
  --gray-1: #{$gray-1};
  --gray-3: #{$gray-3};
  --gray-6: #{$gray-6};
  --gray-9: #{$gray-9};
  --gray-10: #{$gray-10};
  --bs-secondary: #05386b;
  --bs-primary-rgb: 72, 179, 224;
  --bs-secondary-rgb: 05, 56, 107;
  --bs-success-rgb: 56, 201, 118;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 236, 249, 243;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );

  --bs-body-bg: #fff;

  --rs-picker-value: #{$muted-color};
  --rs-listbox-option-hover-bg: #{$primary-color-4};
  --rs-text-primary: #{$gray-9};
  --rs-listbox-option-hover-text: #{$gray-9};
  --rs-listbox-option-selected-text: #{$secondary-color};
  --rs-input-focus-border: #{$border-color};
  --rs-listbox-option-selected-bg: #{$primary-color-4};
  --rs-picker-value: #{$gray-6};
  --rs-border-primary: #{$border-color};
  --rs-text-secondary: #{$gray-6};
  --rs-checkbox-checked-bg: #{$secondary-color};
  --rs-btn-default-bg: #{$white-color};
}

html,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Poppins", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--bs-secondary);
}

*:focus {
  outline: none;
}

/* Scroll Bar */
*::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  background-color: rgb(92, 219, 149);
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: white;
  box-shadow: inset 0 0 5px #45bba6;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 10px;
  background: rgb(92, 219, 149);
}
*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(92, 219, 149);
}
.hidden-scrollbar {
  &::-webkit-scrollbar {
    width: 8px !important;
    height: 0px !important;
    background-color: rgb(92, 219, 149) !important;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px !important;
    background-color: white !important;
    box-shadow: inset 0 0 5px #45bba6 !important;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    height: 0px !important;
    background: rgb(92, 219, 149) !important;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(92, 219, 149) !important;
  }
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #f8f8fb !important;
  max-width: 2560px;
  margin: 0 auto !important;
  font-size: 13px;
  color: var(--bs-secondary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root,
.container-fluid.row {
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield !important;
}
.object-cover {
  object-fit: cover !important;
}
.object-contain {
  object-fit: contain !important;
}
.body-title {
  font-style: normal;
  font-size: 18px;
  line-height: 27px;
}

.bold-text {
  font-weight: bold;
}
.fw-inherit {
  font-weight: inherit !important;
}
.semi-bold-text {
  font-weight: 600;
}

.medium-text {
  font-weight: 500;
}
.w-70 {
  max-width: 70% !important;
}
.w-60 {
  max-width: 60% !important;
}
.w-80 {
  max-width: 80% !important;
}
.bg-website-success {
  background-color: #5cdb95 !important;
}
.btn-website-success {
  background-color: #5cdb95 !important;
  border-color: #5cdb95 !important;
}
.bg-soft-success {
  background-color: #d6f3e9 !important;
}
.bg-soft-info {
  background-color: #f0f9fd !important;
}
.bg-soft-warning {
  background-color: #fefbe8 !important;
}

.body-title-bold {
  @extend .body-title;
  @extend .bold-text;
}

.body-title-semi-bold {
  @extend .body-title;
  @extend .semi-bold-text;
}

.body-title-medium {
  @extend .body-title;
  @extend .medium-text;
}

.body-para {
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
}

.body-para-bold {
  @extend .body-para;
  @extend .bold-text;
}

.body-para-semi-bold {
  @extend .body-para;
  @extend .semi-bold-text;
}

.body-para-medium {
  @extend .body-para;
  @extend .medium-text;
}

.body-para-small {
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}

.body-para-small-bold {
  @extend .body-para-small;
  @extend .bold-text;
}

.body-para-small-semi-bold {
  @extend .body-para-small;
  @extend .semi-bold-text;
}

.body-para-small-medium {
  @extend .body-para-small;
  @extend .medium-text;
}

.body-caption {
  font-style: normal;
  font-size: 10px;
  line-height: 15px;
}

.body-caption-bold {
  @extend .body-caption;
  @extend .bold-text;
}

.body-caption-semi-bold {
  @extend .body-caption;
  @extend .semi-bold-text;
}

.body-caption-medium {
  @extend .body-caption;
  @extend .medium-text;
}

.text-error {
  color: #ea8686;
}
.bg-error {
  background-color: #ea8686;
}

////////// HEADINGS /////////

h1.heading {
  font-size: 36px;
  line-height: 54px;
  font-weight: 600;
  color: $gray-9;

  @include respond-below(lg) {
    font-size: 18px;
    line-height: 27px;
  }
}

h2.heading {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}

h3 {
  font-size: 18px !important;
  line-height: 27px !important;
  font-weight: 500 !important;
  color: $secondary-color !important;
}

h3.normal {
  @extend h3;
  font-weight: 400;
}

h4 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  color: $secondary-color;
}

h5.normal {
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: $secondary-color;
}

p.normal {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: $gray-1;
}

p.small {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: $gray-1;
}

// p {
//     font-size: 12px;
//     line-height: 18px;
//     font-weight: 700;
// }

.caption {
  font-size: 10px !important;
  line-height: 15px;
  font-weight: 500;
}

//////// Anchor ///////////
a {
  text-decoration: none !important;
}

/////// Select ////////////

select:invalid {
  color: $muted-color !important;
}

/* 02.Bootstrap overrid */

.rounded-right {
  border-top-right-radius: 8px;
}

.border-1 {
  border: 1px solid $border-color !important;
}

.border-0 {
  border: 0px !important;
}

@media only screen and (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .border-md-0 {
    border: 0px !important;
  }
}

@media only screen and (min-width: 992px) {
  .border-lg-1 {
    border: 1px solid $border-color !important;
  }
}

.sticky-bottom {
  z-index: 4 !important;
}
.container-fluid {
  height: 100%;
}

/* Cross Browser CSS */
.form-control {
  font-family: "Poppins";
  border-radius: 4px;
  padding: 0.75rem;
  border-color: $border-color;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $muted-color;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $muted-color;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $muted-color;
}

.form-group {
  position: relative;
}

.text-muted {
  color: $muted-color !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.info-message {
  text-align: left;
}

.form-select-lg {
  font-size: 14px;
  padding: 12px 15px;
}

.form-select {
  border-radius: 8px;
}

.bg-light {
  background-color: $white-color !important;
}

.btn-outline-secondary {
  background-color: $white-color !important;
  border: 1px solid $secondary-color;
  color: $secondary-color;

  &:is(:hover, :focus) {
    color: $white-color !important;
    border: 1px solid $secondary-color !important;
    background-color: $secondary-color !important;
  }
}

.btn-secondary.active {
  color: white;
  background-color: var(--bs-secondary) !important;
  border: 1px solid #05386b !important;
}

.btn-outline-light-pill {
  border: 1px solid #e6ebf0;
}

.bg-transparent {
  background-color: transparent !important;
}

.form-label {
  color: $gray-9;
}

// @media (max-width: 991px) {
//     .border-md-0 {
//         border: 0px !important;
//     }
// }

/////////////// NAV  /////////////////

.nav-link {
  @extend p.normal;
  color: $gray-6;
}

.nav-link:hover {
  @extend p.normal;
  color: $gray-10;
}

.nav-item.active a {
  color: $gray-10 !important;
}

.tab-content .active a {
  color: #ffffff !important;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  background-color: $white-color;
  color: $secondary-color;
}

/* 03.Sidebars */
////// AUTH //////
.left-banner {
  position: fixed;
}

.left-banner p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.left-banner h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

////// PERSONAL INFO FLOW /////
.p-info-sidebar {
  background-color: $primary-color-4;
  position: relative;
}

.p-info-sidebar::after {
  content: "";
  display: inline-block;
  right: -9px;
  position: absolute;
  z-index: -1;
  top: 6%;
  transform: rotate(45deg);
  border-style: solid;
  border-color: $primary-color-4;
  border-width: 0px 0 15px 15px;
}

.p-info-sidebar p {
  @extend .sm-text;
  color: $secondary-color-6 !important;
  line-height: 24px;
}

@media (max-width: 991px) {
  .p-info-sidebar {
    background-color: initial;
    background-image: linear-gradient(
      to bottom,
      $primary-color-4,
      rgba($primary-color-4, 0.3)
    );
  }

  .p-info-sidebar::after {
    content: none !important;
  }
}

@media (max-width: 767px) {
  .left-banner {
    width: 100%;
    position: relative;
  }

  .info-message {
    text-align: center;
    font-size: 10px !important;
  }

  .content-md-left {
    text-align: left !important;
  }
}

@media (max-width: 1199px) {
  .back-line {
    text-align: center;
  }
}

/* 04.Buttons */

.btn-secondary {
  border-radius: 4px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  border: 1px solid transparent;
  background-color: #05386b;
  border-color: #05386b;
}

.btn-secondary:hover {
  background: $secondary-color-1;
  border: 1px solid $secondary-color-1;
}

.btn-secondary:active {
  background: var(--bs-secondary);
  color: $secondary-color;
  box-shadow: 0 0 0 0.5px $secondary-color !important;
}

.btn-info {
  font-size: 14px;
}

.btn-light {
  @extend .btn-secondary;
  color: $secondary-color;
  max-width: 120px;
  text-align: center;
}

.btn-light:hover {
  @extend .btn-light;
  background-color: $white-color;
}

.btn-light:active {
  @extend .btn-light;
  background-color: $white-color;
}

.btn-dim {
  background-color: $gray-01 !important;
  color: $gray-9;
  border: 0px !important;
}

/* 05.Common */

.course-landing {
  height: 460px;

  @include respond-below(lg) {
    height: 435px;
  }
}

.dim-text {
  color: $dim-text;
}

.force-two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tutor-lead {
  min-height: $tutor-lead-height;
}

.tutor-tags {
  min-width: $tag-min-width !important;
  max-width: initial !important;
  border-color: $gray-01 !important;
  outline: none !important;

  &:hover,
  &:focus {
    color: $link-color !important;
    outline: none !important;
    border-color: transparent !important;
    box-shadow: 0 0 0 0.1rem rgba($link-color, 0.2) !important;
  }
}

.primary-faded-bg {
  background-color: $success-5;
}

.dropdown-menu {
  @include depth(1);
}

// .dropdown {

// }
.dropdown:not(.header-dropdown) {
  border: 1.5px solid #e6ebf0;
  border-radius: 7px;
}

.dropdown-btn {
  background-color: white;
  color: #05386b !important;
}

.tutor-partners {
  margin: 0px -12px;
  background-color: $secondary-color-00;
}
.form-control-noicon {
  padding: 0.75rem 0.75rem 0.75rem 1rem !important;
}

.tutor-join {
  background-image: url("../images/join-bg.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  text-align: right;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @include respond-below(sm) {
    text-align: center;
    background-position: center;
    background-color: #f0f9fd;
    background-size: contain;
  }

  img {
    width: 100%;
    max-width: 390px;

    @include respond-below(sm) {
      max-width: 200px;
    }
  }
}

.join-tutor-btn {
  a {
    @include respond-below(sm) {
      width: 48%;
    }

    div {
      width: 100%;
      max-width: initial;
    }
  }
}

.overlay-sidebar {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background: linear-gradient(
      359.67deg,
      rgba(0, 0, 0, 0.3) 0.18%,
      rgba(0, 0, 0, 0) 122.97%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  transform: matrix(-1, 0, 0, 1, 0, 0);
  z-index: 0;
}

.overlay-top {
  z-index: 99;
}

.sm-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: $muted-color;
}

.md-text {
  font-size: 14px !important;
  color: $muted-color;
}

.lg-text {
  font-size: 16px !important;
  color: $muted-color;
}

.label-text {
  @extend .md-text;
  color: $gray-9 !important;
}

.signup-toggle {
  height: 90px;
  overflow: hidden;
}

.cart-icon {
  color: $secondary-color;
}

.menu-icon {
  @extend .lg-text;
  @extend .cart-icon;
}

.landing-cover {
  background-color: $primary-color-3;
}

.footer-bg {
  background-color: $secondary-color-1;
}

.footer-bg a:hover {
  text-decoration: underline !important;
  text-decoration-color: $white-color !important;
}

.white-link,
.white-link:hover,
.white-link:focus {
  color: $white-color;
}

.footer-bg h4 {
  @extend h4;
  color: $white-color;
}

.footer-bg h5 {
  @extend h5.normal;
  color: $white-color;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.footer-bg p {
  @extend p.normal;
  color: $white-color;
}

.muted-footer {
  @extend p.normal;
  color: $secondary-color-3 !important;
}

.muted-footer:hover {
  text-decoration: underline !important;
  text-decoration-color: $secondary-color-3 !important;
}

.copyright-tutor:hover {
  text-decoration: none !important;
}

@media (max-width: 991px) {
  .signup-steps {
    background-color: $secondary-color-5;
  }
}

.subscribe-tutor {
  input {
    font-size: 14px;
  }

  .btn-secondary:hover {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
  }

  .btn-secondary:active {
    color: $white-color !important;
  }

  a:hover {
    text-decoration-color: $secondary-color !important;
  }

  a:focus {
    color: $white-color !important;
  }
}
.green-dot {
  min-width: 8px;
  min-height: 8px;
  max-width: 8px;
  max-height: 8px;
  border-radius: 50%;
  background-color: #5cdb95;
}

.back-btn {
  border: 1.7px solid #e6ebf0 !important;
}
.back-btn-box {
  span {
    font-weight: 600;
    font-size: 16px;
    color: #05386b;
  }
}
.text-secondary {
  color: var(--bs-secondary) !important;
}
.tutor-photo-landing {
  height: 65px;
  width: 65px;
  background-color: $gray-3;
}

/* 06.React Suits Fixes */

.rs-picker-input {
  border-radius: 8px;
}

.rs-picker-select-menu-items {
  margin-bottom: 0px;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-left: 12px;
}

.rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
  height: 46px;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  padding-top: 11px;
  padding-bottom: 11px;
  height: 46px;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  top: 12px;
}

.rs-picker-input:not(.rs-picker-tag)
  .rs-picker-toggle.rs-btn-lg
  ~ .rs-picker-tag-wrapper
  .rs-picker-search-input {
  padding: 12px 12px;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  @extend h4;
  font-weight: 500;
}

.rs-picker-toggle-value,
.rs-picker-toggle-placeholder {
  @extend p.normal;
}

.rs-steps-item-status-process .rs-steps-item-icon-wrapper {
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.rs-steps-horizontal .rs-steps-item-content {
  align-items: center;
  display: flex;
}

.rs-steps-horizontal .rs-steps-item-title:after {
  border-color: $secondary-color;
}

.rs-steps-item-icon-wrapper {
  color: $secondary-color-2;
  border: 0px;
  background-color: $secondary-color-5;
}

.rs-steps-item-title {
  font-size: 14px;
  font-weight: 500;
  color: $secondary-color-2;
}

.rs-steps-item-active .rs-steps-item-title {
  color: $secondary-color;
}

.rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $white-color;
}

.rs-steps-item-status-finish .rs-steps-item-title {
  color: $secondary-color;
}

.rs-checkbox label {
  color: $muted-color !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.rs-checkbox-checker {
  padding-top: 5px;
}

@media (max-width: 991px) {
  .rs-steps-item-title {
    font-size: 12px;
  }

  .rs-steps-horizontal .rs-steps-item-title:after {
    top: 12px;
  }

  .rs-steps-item-icon-wrapper {
    background-color: $white-color;
    border-color: $white-color;
    color: $secondary-color;
  }
}

.rs-picker-toggle-wrapper {
  width: 100%;
}

.rs-picker-toggle {
  left: 0;
}

.rs-input-group-addon {
  line-height: initial !important;
  background-color: $white-color;

  .btn-secondary {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
}

.rs-input-group {
  background-color: $white-color;

  input {
    @include respond-below(lg) {
      border-bottom-left-radius: 4px !important;
      border-top-left-radius: 4px !important;
      font-size: 14px;
    }
  }
}

.rs-input-group:not(.rs-input-group-inside)
  .rs-auto-complete:first-child
  .rs-input,
.rs-input-group:not(.rs-input-group-inside)
  .rs-form-control-wrapper:first-child
  > .rs-input,
.rs-input-group:not(.rs-input-group-inside)
  .rs-picker-date:first-child
  .rs-picker-toggle,
.rs-input-group:not(.rs-input-group-inside) > :first-child {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.rs-input-group:not(.rs-input-group-inside) > :last-child {
  background-color: $secondary-color;
}

/* 07 Video Player */

.video-js {
  width: 100% !important;
}

.vjs-poster {
  background-size: cover !important;
  border-radius: 8px !important;
}

.vjs-big-play-centered .vjs-big-play-button {
  top: 45% !important;
  left: 57% !important;
  border-radius: 50% !important;
  width: 62px !important;
  height: 60px !important;
  background-color: $white-color !important;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  top: 6px !important;
  color: $primary-color !important;
}

.vjs-control-bar {
  border-radius: 8px !important;
}

/* 08.Slider fixes */

.carousel__dot--selected {
  background-color: $primary-color !important;
  width: 40px;
  height: 5px;
  border-radius: 5px;
}

.carousel__dot {
  padding: 3px;
  border-radius: 10px;
  margin: 2px;
  background-color: $secondary-color;
}

.carousel__dot-group {
  display: flex;
  justify-content: center;
}

//////////blog//////////
.heading-bold {
  font-weight: 700;
  font-size: 24px;
  color: $gray-10;
}

.border-radius-l-8 {
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
}

.border-radius-r-8 {
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.secondary-color {
  color: $secondary-color;
}

.border-8 {
  border-radius: 8px;
}

.newsletter-background {
  background-color: $primary-color-4;
}

.text-underline {
  text-decoration: underline !important;
}

.fit-content {
  width: fit-content;
}

.secondary-btn-border {
  border: 1px solid $secondary-color !important;
}

.validation-error {
  text-align: left;
  font-size: 12px;
  color: $error !important;
  margin-left: 5px;
}

.srv-validation-message {
  text-align: left;
  font-size: 12px;
  color: $error !important;
  margin-left: 5px;
}

.searchbar {
  position: relative;

  .basic-multi-select {
    margin-bottom: 8px;
    font-size: 13px;
    color: #05386b;

    .select__single-value {
      color: #05386b !important;
    }

    .select__placeholder,
    .select__input-container,
    .select__value-container--has-value {
      margin-left: 0px;
    }
  }

  .language-search {
    position: absolute;
    top: 11px;
    left: 14px;
    z-index: 11;
  }

  @-moz-document url-prefix() {
    .language-search {
      position: absolute;
      top: 26px;
      left: 14px;
      z-index: 11;
    }
  }
}

.dropdown-select__control.dropdown-select__control--is-focused {
  font-size: 16px !important;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .dropdown-select:focus,
  .dropdown-select .select__control input:focus {
    font-size: 1.8vh;
  }
}

.continue-button {
  font-size: 13px !important;
  padding: 8px 16px !important;
  background-color: #05386b !important;
  font-weight: 400 !important;
}

.cancel-button {
  font-size: 13px !important;
  font-weight: 400 !important;
  padding: 7px 15px !important;
  border: 1px solid #05386b !important;
  color: #05386b !important;
}

.text-disabled {
  color: rgb(184, 199, 214);
}

.text-all-disabled *:not(.text-not-disabled) {
  color: rgb(184, 199, 214) !important;
}

.text-all-strikethrough * {
  text-decoration: line-through !important;
}

.text-secondary-dim {
  color: #3e6d9c;
}

.bg-light-gray {
  background-color: #f8f8fb !important;
}

// for background color to be green
.bg-green {
  background-color: $primary-color;
}

// text coloro primary green
.text-green {
  color: $primary-color;
}

// white text
.text-white {
  color: $white-color;
}

.bg-light-gray:focus {
  background-color: #f8f8fb;
}

.bg-danger-light {
  background-color: #fdf3f3;
  box-shadow: none;
  border: 0;
}

.bg-danger-light:hover {
  background-color: #fdf3f3;
  box-shadow: none;
  border: 0;
}

.bg-danger-light:focus {
  background-color: #fdf3f3;
  box-shadow: none;
  border: 0;
}

.bg-success-light {
  background-color: #ecf9f3;
  box-shadow: none;
  border: 0;
}

.bg-success-light:hover {
  background-color: #ecf9f3;
  box-shadow: none;
  border: 0;
}

.bg-success-light:focus {
  background-color: #ecf9f3;
  box-shadow: none;
  border: 0;
}

.form-control:focus {
  box-shadow: none !important;
}

.breadcrumb-item.active {
  color: var(--bs-secondary) !important;
  font-weight: 500;
}

button:focus {
  box-shadow: none !important;
}

input .form-control .editor {
  padding: 5px !important;
}

.react-bootstrap-table input {
  padding: 0px 10px !important;
  font-size: 13px;
}

.react-bootstrap-table .border-bottom:last-child {
  border-color: white !important;
}

.react-bootstrap-table thead th {
  color: #8ca6c0;
  font-weight: 500;
  font-size: 13px;
}
.optional-text {
  font-size: 11px !important;
  color: "#8CA6C0" !important;
}
.btn-dim {
  background-color: $gray-01 !important;
  color: $gray-9 !important;
  border: 0px !important;
}

.btn-outline-secondary-2 {
  border: 1.5px solid var(--bs-secondary);
}

.btn-outline-secondary-2:hover {
  border: 1.5px solid var(--bs-secondary);
}

.bg-body {
  background-color: #ffffff !important;
}

.custom-border:after {
  content: " ";
  position: absolute;
  border-left: 2px $gray-3 solid;
  top: 20%;
  right: 0;
  height: 60%;
  margin-top: auto;
  margin-bottom: auto;
}

.rating-star {
  color: #f3d11a !important;
  fill: #f3d11a !important;
}

.rating {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 2-px;
  letter-spacing: 0px;
  text-align: left;
  color: #05386b;
}

.rating-heading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  color: #05386b;
  margin-bottom: 0;
}

.review-dropdown-btn {
  font-family: "Poppins";
  font-weight: 400;
  color: #05386b !important;
  padding: 5px 10px !important;
}

.review-dropdown-text {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;

  color: #3e6d9c;
}

.review-icon-color {
  background: #f8f8fb;
}

.earnings-withdraw-modal-img-col {
  background-image: url("../images/earning/modal-right-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

/// Tutoring/Tutor/Bundles/Table.
.tutoring-tutor-bundle-table {
  > *:not(:last-child) {
    margin-bottom: 2rem;
  }
}
// learner main dashboard. temporary basis
.learner-dash-sidebar-explore {
  background: $secondary-color-1;
  padding-left: 18px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  border-radius: 4px;

  .learner-dash-sidebar-explore-background {
    position: absolute;
    bottom: 130px;
    right: 0;
    z-index: 10;
    overflow: hidden;
  }
}

// Styles for a list with dots between it's items.
.list-dots {
  > *:not(:first-child)::before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 0.5rem 0.2rem;
    background-color: currentColor;
  }
}
// .list-dots-sm {
//   > *:not(:first-child)::before {
//     content: "";
//     width: 6px;
//     height: 6px;
//     border-radius: 50%;
//     display: inline-block;
//     background-color: gray;
//     margin: 0 0.5rem 0.2rem;
//   }
// }

//
.transaction-details-items {
  & > div:not(:last-child) {
    border-bottom: 1px solid #eff2f7;
  }
}

.items-bottom-borders {
  & > *:not(:last-child) {
    border-bottom: 1px solid #eff2f7;
  }
}

.book-session-tutoring-learner {
  max-height: 220px;
  overflow-y: auto;
}
.new-session-available-items-box {
  max-height: 540px;
  overflow-y: auto;
}

.trending-tutor-products-slider {
  .slick-track {
    display: flex;
    align-items: center;
    width: 100% !important;

    .slick-slide {
      margin: 0 4px;
      flex-grow: 1;
    }
  }
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 62%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    // opacity: 1;
    display: none;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    // opacity: 0.25;
    display: none;
  }

  .slick-prev:before,
  .slick-next:before {
    display: none;
    // font-size: 20px;
    // line-height: 1;

    // opacity: 0.75;
    // color: #05386b;

    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
  }

  .slick-prev {
    left: -19px;
  }
  [dir="rtl"] .slick-prev {
    right: -19px;
    left: auto;
  }

  .slick-next {
    right: -19px;
  }
  [dir="rtl"] .slick-next {
    right: auto;
    left: -19px;
  }
}

// media query for min 992px
@media (min-width: 992px) {
  .min-vh-90-desktop {
    min-height: 90vh;
  }
}
