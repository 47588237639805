/* Outline on focused elements. */
// :is(
//     a,
//     button,
//     input,
//     textarea,
//     .btn,
//     .link,
//     .input,
//     .textarea,
//     .focusable,
//     [tab-index="0"]
//   ):not([disabled]):not(.outline-0) {
//   &:is(:hover, :focus-within) {
//     outline: 1px dashed $gray-400 !important;
//   }
// }

.focusable:not(input, textarea) {
  cursor: pointer;
}

label,
input::placeholder {
  text-transform: capitalize !important;
}

// React-Select's Placeholder.
.select2-selection__placeholder {
  text-transform: capitalize !important;
}

// React strap's input.
.form-control {
  padding: 0.525rem 0.75rem !important;
}

// React form input's input.
.react-tel-input .form-control {
  padding-left: 48px !important;
}
