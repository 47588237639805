/* Tutor Main Style

Table of Contents

00.Variables and Imports
01.Base
02.Bootstrap fixes
03.Sidebars
04.Buttons
05.Common
06.React Suits fixes
07.Video Player
08.Slider fixes
09.Blog
10.Home
11.Jobs
/* 00.Variables and Imports */

$shadowOffsetsTop: 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom: 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import "./_mixins.scss";

$primary-color: #5cdb95;
$primary-color-1: #a0efc3;
$primary-color-2: #b7f1d1;
$primary-color-3: #d1f6e1;
$primary-color-4: #eefbf4;

$secondary-color: #05386b;
$secondary-color-00: #356493;
$secondary-color-1: #3e6d9c;
$secondary-color-2: #6488ac;
$secondary-color-3: #8ca6c0;
$secondary-color-4: #b8c7d6;
$secondary-color-5: #e6ebf0;
$secondary-color-6: #8a9daf;

$gray-01: #eef2f6;
$gray-1: #36414c;
$gray-3: #d5dde5;
$gray-6: #8f9ca9;
$gray-9: #272d37;
$gray-10: #182e45;

$link-color: #48b3e0;
$white-color: #ffffff;
$dark-color: #000000;
$muted-color: #8f9ca9;

$border-color: #d5dde5;
$dim-text: #b8c1cc;

$success: #3cbd7f;
$success-1: #6fd5a4;
$success-4: #c8eddb;
$success-5: #ecf9f3;

$error: #ea8686;
$error-3: #f8c7c7;
$error-5: #fdf3f3;

$info: #48b3e0;
$info-1: #7cccee;
$info-2: #9ad8f2;
$info-3: #b6e3f6;
$info-4: #d4eef9;
$info-5: #f0f9fd;

$warning: #f3d11a;

$tag-min-width: 70px;
$tutor-lead-height: 270px;

/* 01.Base */

:root {
  --primary-color: #{$primary-color};
  --primary-color-1: #{$primary-color-1};
  --primary-color-2: #{$primary-color-2};
  --primary-color-3: #{$primary-color-3};
  --primary-color-4: #{$primary-color-4};
  --secondary-color: #{$secondary-color};
  --secondary-color-1: #{$secondary-color-1};
  --secondary-color-2: #{$secondary-color-2};
  --secondary-color-3: #{$secondary-color-3};
  --secondary-color-4: #{$secondary-color-4};
  --secondary-color-5: #{$secondary-color-5};
  --secondary-color-6: #{$secondary-color-6};

  --dark-color: #{$dark-color};
  --gray-1: #{$gray-1};
  --gray-3: #{$gray-3};
  --gray-6: #{$gray-6};
  --gray-9: #{$gray-9};
  --gray-10: #{$gray-10};

  --rs-picker-value: #{$muted-color};
  --rs-listbox-option-hover-bg: #{$primary-color-4};
  --rs-text-primary: #{$gray-9};
  --rs-listbox-option-hover-text: #{$gray-9};
  --rs-listbox-option-selected-text: #{$secondary-color};
  --rs-input-focus-border: #{$border-color};
  --rs-listbox-option-selected-bg: #{$primary-color-4};
  --rs-picker-value: #{$gray-6};
  --rs-border-primary: #{$border-color};
  --rs-text-secondary: #{$gray-6};
  --rs-checkbox-checked-bg: #{$secondary-color};
  --rs-btn-default-bg: #{$white-color};
}

html,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-family: "Poppins", sans-serif;
}

*:focus {
  outline: none;
}

// body {
//   font-family: "Poppins", sans-serif;
//   max-width: 2560px;
//   margin: 0 auto;
// }

.blog-page-css {
  .body-title {
    font-style: normal;
    font-size: 18px;
    line-height: 27px;
  }

  .bold-text {
    font-weight: bold;
  }

  .semi-bold-text {
    font-weight: 600;
  }

  .medium-text {
    font-weight: 500;
  }

  .body-title-bold {
    @extend .body-title;
    @extend .bold-text;
  }

  .body-title-semi-bold {
    @extend .body-title;
    @extend .semi-bold-text;
  }

  .body-title-medium {
    @extend .body-title;
    @extend .medium-text;
  }

  .body-para {
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
  }

  .body-para-bold {
    @extend .body-para;
    @extend .bold-text;
  }

  .body-para-semi-bold {
    @extend .body-para;
    @extend .semi-bold-text;
  }

  .body-para-medium {
    @extend .body-para;
    @extend .medium-text;
  }

  .body-para-small {
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
  }

  .body-para-small-bold {
    @extend .body-para-small;
    @extend .bold-text;
  }

  .body-para-small-semi-bold {
    @extend .body-para-small;
    @extend .semi-bold-text;
  }

  .body-para-small-medium {
    @extend .body-para-small;
    @extend .medium-text;
  }

  .body-caption {
    font-style: normal;
    font-size: 10px;
    line-height: 15px;
  }

  .body-caption-bold {
    @extend .body-caption;
    @extend .bold-text;
  }

  .body-caption-semi-bold {
    @extend .body-caption;
    @extend .semi-bold-text;
  }

  .body-caption-medium {
    @extend .body-caption;
    @extend .medium-text;
  }

  ////////// HEADINGS /////////

  h1.heading {
    font-size: 36px;
    line-height: 54px;
    font-weight: 600;
    color: $gray-9;

    @include respond-below(lg) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  h2.heading {
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
  }

  h3 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    color: $secondary-color;
  }

  h3.normal {
    @extend h3;
    font-weight: 400;
  }

  h4 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    color: $secondary-color;
  }

  h5.normal {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: $secondary-color;
  }

  p,
  .normal {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: $gray-1;
  }

  p.small {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $gray-1;
  }

  // p {
  //     font-size: 12px;
  //     line-height: 18px;
  //     font-weight: 700;
  // }

  .caption {
    font-size: 10px !important;
    line-height: 15px;
    font-weight: 500;
  }

  //////// Anchor ///////////
  a {
    text-decoration: none !important;
  }
  /////// Select ////////////

  select:invalid {
    color: $muted-color !important;
  }

  /* 02.Bootstrap overrid */

  .rounded-right {
    border-top-right-radius: 8px;
  }

  .border-1 {
    border: 1px solid $border-color !important;
  }

  .border-0 {
    border: 0px !important;
  }

  @media only screen and (min-width: 768px) {
    .text-md-left {
      text-align: left !important;
    }

    .border-md-0 {
      border: 0px !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .border-lg-1 {
      border: 1px solid $border-color !important;
    }
  }

  .container-fluid {
    height: 100%;
  }

  /* Cross Browser CSS */
  .form-control {
    font-family: "Poppins";
    border-radius: 8px;
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    border-color: $border-color;
  }
  .form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $muted-color;
    opacity: 1; /* Firefox */
  }

  .form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $muted-color;
  }

  .form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $muted-color;
  }

  .form-group {
    position: relative;
  }

  .text-muted {
    color: $muted-color !important;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }

  .info-message {
    text-align: left;
  }

  .form-select-lg {
    font-size: 14px;
    padding: 12px 15px;
  }

  .form-select {
    border-radius: 8px;
  }

  .bg-light {
    background-color: $white-color !important;
  }

  .btn-outline-secondary {
    background-color: $white-color !important;
    border: 1px solid $secondary-color;
    color: $secondary-color;
  }

  .btn-outline-secondary:hover {
    @extend .btn-outline-secondary;
  }

  .bg-transparent {
    background-color: transparent !important;
  }

  .form-label {
    color: $gray-9;
  }

  // @media (max-width: 991px) {
  //     .border-md-0 {
  //         border: 0px !important;
  //     }
  // }

  /////////////// NAV  /////////////////

  .nav-link {
    @extend p, .normal;
    color: $gray-6;
  }

  .nav-link:hover {
    @extend p, .normal;
    color: $gray-10;
  }

  .nav-item.active a {
    color: $gray-10 !important;
  }

  .tab-content .active a {
    color: #ffffff !important;
  }

  .navbar-toggler,
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    background-color: $white-color;
    color: $secondary-color;
  }

  /* 03.Sidebars */

  ////// AUTH //////
  .left-banner {
    position: fixed;
  }

  .left-banner p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }

  .left-banner h1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
  }

  ////// PERSONAL INFO FLOW /////
  .p-info-sidebar {
    background-color: $primary-color-4;
    position: relative;
  }

  .p-info-sidebar::after {
    content: "";
    display: inline-block;
    right: -9px;
    position: absolute;
    z-index: -1;
    top: 6%;
    transform: rotate(45deg);
    border-style: solid;
    border-color: $primary-color-4;
    border-width: 0px 0 15px 15px;
  }

  .p-info-sidebar p {
    @extend .sm-text;
    color: $secondary-color-6 !important;
    line-height: 24px;
  }

  @media (max-width: 991px) {
    .p-info-sidebar {
      background-color: initial;
      background-image: linear-gradient(
        to bottom,
        $primary-color-4,
        rgba($primary-color-4, 0.3)
      );
    }

    .p-info-sidebar::after {
      content: none !important;
    }
  }

  @media (max-width: 767px) {
    .left-banner {
      width: 100%;
      position: relative;
    }
    .info-message {
      text-align: center;
      font-size: 10px !important;
    }
    .content-md-left {
      text-align: left !important;
    }
  }

  @media (max-width: 1199px) {
    .back-line {
      text-align: center;
    }
  }

  /* 04.Buttons */

  .btn-secondary {
    padding: 10px 16px;
    border-radius: 4px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }
  .btn-secondary:hover {
    background: $secondary-color-1;
    border: 1px solid $secondary-color-1;
  }

  .btn-secondary:active {
    background: $white-color;
    color: $secondary-color;
    box-shadow: 0 0 0 0.5px $secondary-color !important;
  }

  .btn-light {
    @extend .btn-secondary;
    color: $secondary-color;
    max-width: 120px;
    text-align: center;
  }

  .btn-light:hover {
    @extend .btn-light;
    background-color: $white-color;
  }

  .btn-light:active {
    @extend .btn-light;
    background-color: $white-color;
  }

  .btn-dim {
    background-color: $gray-01 !important;
    color: $gray-9;
    border: 0px !important;
  }

  /* 05.Common */

  .course-landing {
    height: 460px;

    @include respond-below(lg) {
      height: 435px;
    }
  }

  .dim-text {
    color: $dim-text;
  }

  .force-two-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tutor-lead {
    min-height: $tutor-lead-height;
  }

  .tutor-tags {
    min-width: $tag-min-width !important;
    max-width: initial !important;
    border-color: $gray-01 !important;
    outline: none !important;
    margin-right: 10px;

    &:hover,
    &:focus {
      color: $link-color !important;
      outline: none !important;
      border-color: transparent !important;
      box-shadow: 0 0 0 0.1rem rgba($link-color, 0.2) !important;
    }
  }

  .primary-faded-bg {
    background-color: $success-5;
  }

  .dropdown-menu {
    @include depth(1);
  }

  .tutor-partners {
    margin: 0px -12px;
    background-color: $secondary-color-00;
  }

  .join-block {
    margin-top: 96px !important;
    margin-left: 45px !important;

    @include respond-below(md) {
      margin-top: 0px !important;
      margin-left: 0px !important;
    }
  }

  .tutor-join {
    background-image: url("../../images/join-bg.svg");
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    text-align: right;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    @include respond-below(sm) {
      text-align: center;
      background-position: center;
      background-color: #f0f9fd;
      background-size: contain;
    }

    img {
      width: 100%;
      max-width: 390px;

      @include respond-below(sm) {
        max-width: 200px;
      }
    }
  }

  .join-tutor-btn {
    a {
      @include respond-below(sm) {
        width: 48%;
      }

      div {
        width: 100%;
        max-width: initial;
      }
    }
  }

  .overlay-sidebar {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background: linear-gradient(
        359.67deg,
        rgba(0, 0, 0, 0.3) 0.18%,
        rgba(0, 0, 0, 0) 122.97%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: 0;
  }

  .overlay-top {
    z-index: 99;
  }

  .sm-text {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: $muted-color;
  }

  .md-text {
    font-size: 14px !important;
    color: $muted-color;
  }

  .lg-text {
    font-size: 16px !important;
    color: $muted-color;
  }

  .label-text {
    @extend .md-text;
    color: $gray-9 !important;
  }

  .signup-toggle {
    height: 90px;
    overflow: hidden;
  }

  .cart-icon {
    color: $secondary-color;
  }

  .menu-icon {
    @extend .lg-text;
    @extend .cart-icon;
  }

  .landing-cover {
    background-color: $primary-color-3;
  }

  .footer-bg {
    background-color: $secondary-color-1;
  }

  .footer-bg a:hover {
    text-decoration: underline !important;
    text-decoration-color: $white-color !important;
  }

  .white-link,
  .white-link:hover,
  .white-link:focus {
    color: $white-color;
  }

  .footer-bg h4 {
    @extend h4;
    color: $white-color;
  }

  .footer-bg h5 {
    @extend h5, .normal;
    color: $white-color;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .footer-bg p {
    @extend p, .normal;
    color: $white-color;
  }

  .muted-footer {
    @extend p, .normal;
    color: $secondary-color-3 !important;
  }

  .muted-footer:hover {
    text-decoration: underline !important;
    text-decoration-color: $secondary-color-3 !important;
  }

  .copyright-tutor:hover {
    text-decoration: none !important;
  }

  @media (max-width: 991px) {
    .signup-steps {
      background-color: $secondary-color-5;
    }

    .main-heading {
      margin-bottom: 1rem !important;
    }
  }

  .subscribe-tutor {
    input {
      font-size: 14px;
    }
    .btn-secondary:hover {
      background-color: $secondary-color;
      border: 1px solid $secondary-color;
    }
    .btn-secondary:active {
      color: $white-color !important;
    }
    a:hover {
      text-decoration-color: $secondary-color !important;
    }
    a:focus {
      color: $white-color !important;
    }
  }

  .tutor-photo-landing {
    height: 65px;
    width: 65px;
    background-color: $gray-3;
  }

  /* 06.React Suits Fixes */

  .rs-picker-input {
    border-radius: 8px;
  }

  .rs-picker-select-menu-items {
    margin-bottom: 0px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-left: 12px;
  }

  .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
    height: 46px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-top: 11px;
    padding-bottom: 11px;
    height: 46px;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
    top: 12px;
  }

  .rs-picker-input:not(.rs-picker-tag)
    .rs-picker-toggle.rs-btn-lg
    ~ .rs-picker-tag-wrapper
    .rs-picker-search-input {
    padding: 12px 12px;
  }

  .rs-picker-select-menu-item.rs-picker-select-menu-item-active {
    @extend h4;
    font-weight: 500;
  }

  .rs-picker-toggle-value,
  .rs-picker-toggle-placeholder {
    @extend p, .normal;
  }

  .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    background-color: $secondary-color;
    border-color: $secondary-color;
  }

  .rs-steps-horizontal .rs-steps-item-content {
    align-items: center;
    display: flex;
  }

  .rs-steps-horizontal .rs-steps-item-title:after {
    border-color: $secondary-color;
  }

  .rs-steps-item-icon-wrapper {
    color: $secondary-color-2;
    border: 0px;
    background-color: $secondary-color-5;
  }

  .rs-steps-item-title {
    font-size: 14px;
    font-weight: 500;
    color: $secondary-color-2;
  }

  .rs-steps-item-active .rs-steps-item-title {
    color: $secondary-color;
  }

  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $white-color;
  }

  .rs-steps-item-status-finish .rs-steps-item-title {
    color: $secondary-color;
  }

  .rs-checkbox label {
    color: $muted-color !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .rs-checkbox-checker {
    padding-top: 5px;
  }

  @media (max-width: 991px) {
    .rs-steps-item-title {
      font-size: 12px;
    }
    .rs-steps-horizontal .rs-steps-item-title:after {
      top: 12px;
    }
    .rs-steps-item-icon-wrapper {
      background-color: $white-color;
      border-color: $white-color;
      color: $secondary-color;
    }
  }

  .rs-picker-toggle-wrapper {
    width: 100%;
  }

  .rs-picker-toggle {
    left: 0;
  }

  .rs-input-group-addon {
    line-height: initial !important;
    background-color: $white-color;

    .btn-secondary {
      border-bottom-left-radius: 0px !important;
      border-top-left-radius: 0px !important;
    }
  }

  .rs-input-group {
    background-color: $white-color;

    input {
      @include respond-below(lg) {
        border-bottom-left-radius: 4px !important;
        border-top-left-radius: 4px !important;
        font-size: 14px;
      }
    }
  }

  .rs-input-group:not(.rs-input-group-inside)
    .rs-auto-complete:first-child
    .rs-input,
  .rs-input-group:not(.rs-input-group-inside)
    .rs-form-control-wrapper:first-child
    > .rs-input,
  .rs-input-group:not(.rs-input-group-inside)
    .rs-picker-date:first-child
    .rs-picker-toggle,
  .rs-input-group:not(.rs-input-group-inside) > :first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }

  .rs-input-group:not(.rs-input-group-inside) > :last-child {
    background-color: $secondary-color;
  }

  /* 07 Video Player */

  .video-js {
    width: 100% !important;
  }

  .vjs-poster {
    background-size: cover !important;
    border-radius: 8px !important;
  }

  .vjs-big-play-centered .vjs-big-play-button {
    top: 45% !important;
    left: 57% !important;
    border-radius: 50% !important;
    width: 62px !important;
    height: 60px !important;
    background-color: $white-color !important;
  }

  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    top: 6px !important;
    color: $primary-color !important;
  }

  .vjs-control-bar {
    border-radius: 8px !important;
  }

  /* 08.Slider fixes */

  .carousel__dot--selected {
    background-color: $primary-color !important;
    width: 40px;
    height: 5px;
    border-radius: 5px;
  }

  .carousel__dot {
    padding: 3px;
    border-radius: 10px;
    margin: 2px;
    background-color: $secondary-color;
  }

  .carousel__dot-group {
    display: flex;
    justify-content: center;
  }

  //////////blog//////////

  .heading-bold {
    font-weight: 700;
    font-size: 24px;
    color: $gray-10;
  }
  .border-radius-l-8 {
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }
  .border-radius-r-8 {
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  .secondary-color {
    color: $secondary-color;
  }
  .border-8 {
    border-radius: 8px;
  }
  .newsletter-background {
    background-color: $primary-color-4;
  }

  .newsletter-background.newsletter-letter {
    // margin-left: 33px !important;
    @include respond-below(md) {
      // margin-left: 0px !important;
    }
  }
  .text-underline {
    text-decoration: underline !important;
  }
  .fit-content {
    width: fit-content;
  }
  .rs-input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    border-radius: 6px;
    -webkit-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    width: 100%;
    cursor: text;
    color: #575757;
    color: var(--rs-text-primary);
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
  }
  .rs-input-group .rs-input-group-addon {
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .rs-input-group-addon {
    line-height: normal !important;
    background-color: #fff;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: middle;
    color: #575757;
    color: var(--rs-text-primary);
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    background-color: #f7f7fa;
    background-color: var(--rs-btn-default-bg);
    border: 1px solid #e5e5ea;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
  }
  .secondary-btn-border {
    border: 1px solid #05386b !important;
  }
  a {
    color: $link-color;
  }
  .sliderTray___-vHFQ {
    display: flex !important;
    flex-direction: unset;
  }
}

///////BLOG DETAILS//////

.heading.blog-detail-heading {
  text-align: left;
  font-size: 32px !important;
}

.blog-details-shareblock {
  border: 1px solid $gray-3;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background: white;
}

.blog-details-share {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-details-share-text {
  font-size: 14px;
  color: $muted-color;
}

.blog-details-share-icon {
  margin-right: 24px;
  font-size: 17px;
}

.blog-card {
  height: 331px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.blog-card-img-container {
  width: 367px;
  height: 180px;
}

.blog-card-img {
  object-fit: fit;
  width: 100%;
  height: 100%;
}

.blog-card-content {
  flex: 1;
  background: lightblue !important;
}

.blog-tags {
  // margin-left: 56px;
  // @include respond-below(xs) {
  //   margin-left: 0px;
  // }
}

.blog-category-card {
  margin-top: 75px;
  @include respond-below(xs) {
    margin-top: 0px;
  }
}

.blog-cateogory-span {
  font-size: 12px;
}

.blog-container-xs {
  @include respond-below(xs) {
    padding: 0 !important;
  }
}
.blogcards-content {
  padding-right: 16px;
}
.blog-post {
  // margin-left: 5px;
  // @include respond-below(xs) {
  //   margin-left: 0px;
  // }
}

.blog-detail-container {
  position: relative;
  margin-top: 21px;
  @include respond-below(sm) {
    margin-top: 130px;
  }
  @include respond-below(xs) {
    margin-top: 90px;
  }
}

.blog-detail {
  display: flex;
  @include respond-below(sm) {
    flex-direction: column;
  }
  @include respond-below(xs) {
    flex-direction: column;
  }
}

.blog-details-tags {
  margin-top: 16px;
  @include respond-below(xs) {
    margin-top: 10px;
    margin-left: 10px;
  }
}

.blog-detail-author-img {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin-right: 12px;
}

.blog-detail-author-name {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.blog-detail-right {
  flex: 1;
  margin-top: 60px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;

  @include respond-below(xs) {
    margin-top: 0px;
  }
}
.blogcard-detail-content {
  margin-right: 16px;
  @include respond-below(xs) {
    margin-right: 0px;
  }
}
.sticky-block {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  align-self: flex-end;
  padding-right: 16px;

  @include respond-below(xs) {
    padding-right: 0;
  }
}

.blog-detail-ad {
  margin-top: 40px;
  background: $secondary-color-5;
  border-radius: 4px;
  height: 140px;

  @include respond-below(xs) {
    margin-top: 14px;
  }
}

.blog-detail-ad-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.blog-detail-ad-1 {
  margin-top: 14px;
  background: $info-2;
  // padding: 30px 0;
  text-align: center;
  border-radius: 4px;
  height: 104px;
}

.blog-breadcrumb {
  font-size: 10px;
  color: #66737f;
  font-weight: 400;
  margin-top: 21px;
  margin-bottom: 21px;

  // @include respond-below(xs) {
  //   margin: 0px;
  // }

  &-home {
    font-weight: 500;
    line-height: 18px;
    color: $gray-9;
  }
}

/////blog sidebar/////

.blog-content {
  margin-top: 3rem;

  @include respond-below(xs) {
    margin-top: 1rem;
  }
}
.blog-category {
  margin-left: 50px;
  @include respond-below(xs) {
    margin-left: 0;
  }
}

.blog-sidebar {
  width: 302px;
  background: $secondary-color-5;
  padding: 10px 12.5px;
  border-radius: 6px;
  margin-top: 26px;
  font-size: 10px;

  @include respond-below(xs) {
    width: 100%;
    margin-top: 10px;
  }

  .sidebar-input-container {
    position: relative;
    z-index: 100;
  }

  .sidebar-search {
    position: absolute;
    top: 10px;
    right: 27px;
    font-size: 20px;
    &:hover {
      cursor: pointer;
    }
    @include respond-below(md) {
      top: 16px;
    }
  }

  .sidebar-center {
    color: $link-color;
    margin-top: 13px;
    display: flex;
    align-items: center;
    justify-content: end;

    & span {
      color: $link-color !important;
      margin-right: 20px !important;
    }
  }

  .sidebar-input-container {
    & > input {
      padding: 8px 16px;
      border-radius: 4px;
      border: none;
      font-weight: 500;
      font-size: 14px;
      width: 100%;
      border-radius: 5px;
    }

    @include respond-below(xs) {
      display: none;
    }

    &::placeholder {
      color: #8f9ca9;
    }
  }

  .blog-sidebar-content {
    margin-top: 12px;
    padding: 0 10px;

    @include respond-below(xs) {
      margin-top: 0px;
      margin-bottom: 0;
      padding: 0;
    }

    & h4 {
      font-weight: 600;
      // font-size: 18px;
      line-height: 27px;
    }

    & p {
      // font-size: 14px;
      line-height: 20px;
      color: #8f9ca9;
    }
  }
}

.sidebarcards {
  background: white;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  font-size: 10px;

  @include respond-below(xs) {
    margin-bottom: 12px;
  }

  .sidebarcards-left {
    width: max-content;
    margin-right: 10px;

    .sidebarcards-link {
      color: #272d37 !important;
    }

    .sidebarcard-img {
      width: 69px;
      height: 69px;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  .sidebarcards-right {
    .sidebarcard-tag {
      font-size: 10px;
      font-weight: 500;
      padding: 6px 4px;
      background: transparent;
      border: none;
      outline: none;
      white-space: nowrap;
    }

    .date-sidebar {
      color: #b8c1cc;
      font-size: 10px;
      font-weight: 400;
    }
    .title-container {
      align-items: center;
      justify-content: space-between;
    }

    .sidebar-title {
      font-size: 12px;
      font-weight: 500;
      color: #272d37;
    }
  }
  .author-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    .author-name {
      color: $secondary-color;
      font-weight: 600;
    }

    .author-title-container {
      font-size: 10px;
      width: max-content;
      font-weight: normal;
      margin-right: auto;
      color: $dim-text;
    }
  }
}

.blog-input-container {
  display: none;

  @include respond-below(xs) {
    display: block;
    margin-top: 24.5px;
  }

  position: relative;
  width: 100%;
  & input {
    @include respond-below(xs) {
      padding: 12px 14px;
      padding: 12px 16px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid $gray-3;
    }
    &::placeholder {
      color: #8f9ca9;
    }
  }
}

.blog-search {
  position: absolute;
  top: 16px;
  right: 18px;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
}

.category-hero {
  background: $primary-color-3;
  margin-top: 16px;
  padding-bottom: 34px;
  // padding-left: 62px;

  @include respond-below(md) {
    padding-left: 16px;
    margin: 20px 16px;
  }
}



.category-hero-title {
  font-size: 36px;
  line-height: 54px;
  color: $gray-10;
  font-weight: 600;
  text-transform: capitalize;

  @include respond-below(md) {
    font-size: 24px;
    line-height: 36px;
  }
}

.category-hero-subtitle {
  font-size: 14px;
  line-height: 21px;
  color: $gray-6;
  font-size: 400;
}

.category-breadcrumb {
  padding: 24px 0;
  color: $secondary-color;
  font-weight: 400;
  font-size: 10px;

  & span {
    font-weight: 500;
  }

  @include respond-below(md) {
    margin-top: 94px;
  }
}

.category-card {
  margin-left: 130px;

  @include respond-below(md) {
    margin: 0px auto;
  }
}
.category-name{
  font-size: 20px !important;
}
.category-card-desc {
  margin-top: 36px;
  margin-bottom: 32px;
  // margin-left: 152px;

  &-para {
    text-transform: capitalize;
  }

  & p {
    font-size: 14px;
    font-weight: 500;
  }

  @include respond-below(md) {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 16px;
  }
}

.category-card-load {
  display: flex;
  justify-content: center;
  color: $link-color;
  margin-top: 24px;

  & p {
    color: $link-color;
    font-weight: 500;
    font-size: 14px;
    padding-right: 38px;
  }
}

//-----home header-------------------

.home-header {
  position: absolute;

  // @include respond-below(md) {
  //   top: 16px;
  // }
}

.blog-cards-container {
  margin-top: 40px;
  @include respond-below(md) {
    margin-top: 10px;
  }
}

.top-tags-container {
  margin-top: 75px;
  @include respond-below(md) {
    margin-top: 20px;
  }
}

.join-container {
  @include respond-below(md) {
    margin-top: 20px;
  }
}

.newsletter-container {
  margin-top: 75px;
  @include respond-below(md) {
    margin-top: 20px;
  }
  @include respond-below(xs) {
    margin-top: 20px;
    display: none;
  }
}

.blog-category-container {
  margin-top: 55px;
  @include respond-below(md) {
    margin-top: 15px;
  }
}

.blog-category-cards-container {
  margin-top: 40px;
  @include respond-below(md) {
    margin-top: 10px;
  }
}

.row-container {
  .date-container {
    width: max-content;
  }
  .description-container {
    min-height: 180px;
    justify-content: flex-end;
  }
  .block-card-author {
    font-size: 14px;
    font-weight: 500;
  }
}

//jobs

.jobs-container {
  margin-top: 90px;
}

.join-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#navbarResponsive {
  display: flex;
  // justify-content: center;
  align-items: center;

  & li {
    list-style: none;
  }

  @include respond-below(md) {
    display: none;
  }
}

.jobs-nav-link {
  min-width: 150px;
  font-size: 14px;
  color: #fff;
}

.jobs-mobile-menu {
  display: none;
  margin: 10px 0;
  @include respond-below(xs) {
    display: flex;
    flex-direction: column;
  }
}

.jobs-nav-button {
  color: white;
  font-size: 14px !important;
  margin-right: 10px;
  min-width: 150px;
  @include respond-below(xs) {
    margin-right: 0px;
    min-width: 100%;
    font-size: 11px !important;
  }
}

.primary-color-button-fix{
  background-color: #05386b !important;
  color: white;
}

.landing-join-button {
  background-color: #05386b !important;
  color: white;
  font-size: 14px !important;
  margin-right: 10px;
  min-width: 150px;
  @include respond-below(xs) {
    margin-right: 0px;
    font-size: 11px !important;
  }
}

.jobs-main-buttons {
  @include respond-below(xs) {
    display: none;
  }
}

.join-main-contianer {
  padding: 1.5rem 0;
}
.jobs-toggle-button {
  display: none;

  @include respond-below(xs) {
    display: block;
    padding: 0.8rem;
  }
}

.footer-important-links {
  display: flex;
  flex-direction: column;
}

.important-link-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 32px;
  text-transform: uppercase;
  padding-bottom:3rem;

  @include respond-below(xs) {
    margin: 10px 0;
    padding-bottom: 1rem;
  }
}

.important-button {
  font-size: 0.8rem !important;
}

.landing-content{
  font-size: 0.95rem !important;
}

.landing-join-button-container{
  display: flex;
  justify-content: center;
}

.blog-detail-heading-image{
  height: 70vh;
  background-position: cover;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 16px;

  @include respond-below(md) {
    height: 30vh;
  }

  @include respond-below(xs) {
    height: 250px;
  }
}

.tooltip > div.tooltip-inner {
  background-color: $primary-color;
  color: white ;
}

.tooltip.show {
  opacity: 1;
}

.tooltip > div.arrow::before {
  border: $primary-color ;
  color: white ;
}
.category-tag{
  width: 100%;
}
.category-tag-button{
  background: #3cbd7f !important;
  color: $white-color !important;
  float: right;

}