.nav-tabs-custom {
  .nav-item {
    position: relative;
    color: #8ca6c0;
    flex-grow: 0;
    flex-basis: max-content;

    .nav-link {
      text-align: center;
      border: none;

      &::after {
        content: "";
        background: var(--bs-secondary);
        height: 2px;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: -1px;
        transition: all 250ms ease 0s;
        transform: scale(0);
      }

      &.active {
        color: var(--bs-secondary);
        font-weight: 600;
        &:after {
          transform: scale(1);
        }
      }
    }
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    background: transparent !important;
  }
}
