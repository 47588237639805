@import "../../../assets/scss/styles.scss";

.user-account-input input {
  padding: 0.75rem 0.75rem 0.75rem 1rem !important;
}

.regular-checkbox {
  // -webkit-appearance: none !important;
  // background-color: #05386b !important;
  // border: 1px solid #fff !important;
}

.tutor-textarea {
  width: 100%;
  height: 3.5rem;
  padding: 0.5rem 0.75rem;
  border: 1px solid #e6ebf0;
  font-size: 13px;
  color: #05386b;
  border-radius: 4px;
}

//experience
.experience-country-select {
  width: 100%;
  font-size: 13px;
}
.experience-back {
  padding: 10px 6px;
  background: #f8f8fb;
}

.experience-school {
  margin-right: 10px;
}

.experience-circle {
  margin: 0 6px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #5cdb95;
}

.experience-container {
  display: flex;
  align-items: center;
}

.experience-container1 {
  margin-left: 24px;
}

.experience-jobtitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: $secondary-color;
  margin-bottom: 0px;
}

.experience-duration {
  font-size: 13px;
  // margin-left: 13px;
}
.experience-company {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: $secondary-color;
}

//tutor-profile-setup

.tutor-profile-setup {
  .heading-text {
    padding-bottom: 24px;
  }

  .tutor-profile-setup-heading {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    padding: 30px 0px;
    color: #05386b;
  }

  .tutor-profile-setup-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #3e6d9c;
  }

  .language-max {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    color: #8ca6c0;
    margin-left: 8px;
  }
}

//tutor setup payment
.tutor-setup-payment {
  .user-account-input input {
    padding: 0.75rem 0.75rem 0.75rem 1rem !important;
    position: relative;
  }

  .user-account-input.form-group {
    .font-icons {
      right: 15px;
      left: auto;
      top: 36px;
      z-index: 1000;
      position: absolute;
      color: #05386b;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

// On board Progress Section

.onboardprogress {
  padding: 0 24px;

  .onboardprogress-setup-profile {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }

  .onboardprogress-content {
    display: flex;
    flex-direction: column;
    margin: 0 8px;
  }

  .visible-none {
    visibility: hidden;
  }

  .onboardprogress-content-details {
    font-size: 12px;
    line-height: 20px;
    color: #3e6d9c;
    font-weight: 400;
    margin-bottom: 16px;
  }

  .onboardprogress-heading {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #05386b;
  }

  .onboardprogress-done-icon {
    color: white;
   width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: rgb(56, 201, 118);
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .onboardprogress-steps {
    display: flex;
    margin-left: 26px;
    margin-bottom: 12px;
  }
  .onboardprogress-steps-icon {
    color: #8ca6c0;
    border: 1.25px solid currentColor;
    border-radius: 50%;
    padding: 3px;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .onboardprogress-steps-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #05386b;
    margin-left: 8px;
    margin-right: 4px;
  }
}

.setup-payment-agree {
  margin-left: 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #05386b;
}

.setup-payment-skip {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #05386b;

  &:hover {
    cursor: pointer;
  }
}

.setup-profile-continue-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  margin-left: 14px;
}

.terms-and-conditions {
  padding: 0 12px;
}

.terms-and-conditions-styles {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #48b3e0;
}
