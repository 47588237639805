* {
  scrollbar-width: thin !important;
}

/* My Toast Styles. */
.Toastify__toast-container {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transform: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.Toastify__toast {
  max-width: 350px;
  border-radius: 4px;
  cursor: auto !important;
  width: calc(100vw - 2rem);
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Hide every toast. */
  display: none !important;
}

.Toastify__toast:last-child {
  /* Except for the last one. */
  display: block !important;
}

.Toastify__toast.md {
  max-width: 557px;
}

.Toastify__toast.lg {
  max-width: 992px;
}

.Toastify__toast.xl {
  max-width: 1200px;
}

.Toastify__toast-body {
  padding: 0;
}

.toastExit {
  animation: 0s toastExit forwards;
}

.toastEnter {
  animation: 0s toastEnter forwards;
}

@keyframes toastExit {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes toastEnter {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* My tour styles */
.shepherd-highlight {
  pointer-events: none !important;
}

.shepherd-header {
  padding: 1rem !important;
  background-color: #fff !important;
  border-bottom: 1px solid #e5e5e5 !important;
}

.shepherd-header .shepherd-title {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.shepherd-text {
  padding: 1rem !important;
  font-size: 13px !important;
}

.shepherd-footer {
  padding: 1rem !important;
  background-color: #fff !important;
  border-top: 1px solid #e5e5e5 !important;
}

.shepherd-footer .shepherd-button {
  /* The base colors */
  --shepherd-button-base-color: rgb(var(--bs-secondary-rgb));
  --shepherd-button-base-color-75: rgba(var(--bs-secondary-rgb), 0.75);
  /* The base button properties */
  --shepherd-button-text-color: #fff;
  --shepherd-button-border-color: var(--shepherd-button-base-color);
  --shepherd-button-background-color: var(--shepherd-button-base-color);
  /* Base styles */
  font-size: 14px;
  border-radius: 4px;
  flex-basis: 100% !important;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* Apply the variables */
  color: var(--shepherd-button-text-color) !important;
  border-color: var(--shepherd-button-border-color) !important;
  background-color: var(--shepherd-button-background-color) !important;
}

.shepherd-footer .shepherd-button:is(:hover, :focus) {
  --shepherd-button-border-color: var(--shepherd-button-base-color-75);
  --shepherd-button-background-color: var(--shepherd-button-base-color-75);
}

.shepherd-footer .shepherd-button.shepherd-button-secondary {
  --shepherd-button-text-color: var(--shepherd-button-base-color);
  --shepherd-button-border-color: var(--shepherd-button-base-color);
  --shepherd-button-background-color: #fff;
}

.shepherd-footer .shepherd-button.shepherd-button-secondary:is(:hover, :focus) {
  --shepherd-button-text-color: #fff;
  --shepherd-button-border-color: var(--shepherd-button-base-color);
  --shepherd-button-background-color: var(--shepherd-button-base-color);
}

.shepherd-arrow::before {
  border-radius: 4px;
  background-color: white !important;
  border-top: 1px solid #e5e5e5 !important;
}

/* Phone number input */
.react-tel-input .special-label {
  display: none !important;
}

.react-tel-input input {
  height: 40px !important;
}

/* React select */
.select2-selection__control {
  min-height: 40px !important;
}

.tutor-dashboard-card-img {
  height: 72px;
  width: 72px;
}
