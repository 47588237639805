.navbar-nav {
  margin-left: auto;
}

.navbar {
  overflow: hidden;
  /* background-color: #333; */
  position: fixed;
  top: 0;
  width: 100%;
}
.primary-color-button-fix {
  background-color: #05386b !important;
  color: white;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: white;
  /* position: fixed; */
  height: 100%;
  overflow: auto;
  margin-top: -15px;
}

.sidebar div {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: white;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.line {
  position: absolute;
  width: 436px;
  height: 0px;
  left: 43px;
  top: 130px;

  /* ☑️ Style v0.2/Neutral/04 */

  border: 1px solid #e6ebf0;
  transform: rotate(90deg);
}

.setupText {
  position: static;
  width: 152px;
  height: 24px;
  left: 48px;
  top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;
  color: #05386b;
  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 8px;
}

.proComTextStyle {
  position: static;
  width: 128px;
  height: 24px;
  left: 49px;
  top: 12px;
  /* ☑️ Style v0.2/Headings/05 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  /* margin: 0px 4px; */
  /* margin-top: -20px; */
}

#myProgress2 {
  width: 100%;
  background-color: #ddd;
  margin-top: -30px !important;
}

#myBars2 {
  width: 8%;
  height: 6px;
  background-color: #5cdb95;
  text-align: center;
  line-height: 30px;
  color: white;
}

.vertical {
  border-left: 2px solid black;
  height: 800px;
}

.bioTextStyling {
  position: static;
  left: 0%;
  right: 97.43%;
  top: 0%;
  bottom: 76.19%;
  /* ☑️ Style v0.2/Captions/Label */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  /* ☑️ Style v0.2/Neutral/01 */
  color: #3e6d9c;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.terms-condition-underline {
  text-decoration: underline !important;
  color: #3e6d9c !important;
}

.bioPragraphStylings {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */
  /* ☑️ Style v0.2/Primary/02 */

  color: #3e6d9c;
  margin: 0px 10px;
}

.bioPragraphStyling {
  position: static;
  left: 1.54%;
  right: 1.54%;
  top: 14.29%;
  bottom: 14.29%;

  /* ☑️ Style v0.2/Forms/01 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 10px;
}

.tenTextStyle {
  position: static;
  width: 269px;
  height: 36px;
  left: 0px;
  top: 0px;
  /* ☑️ Style v0.2/Headings/02 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}

.bInfoText {
  position: static;
  width: 139px;
  height: 24px;
  left: 24px;
  top: 24px;

  /* ☑️ Style v0.2/Headings/05 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

.fillText {
  position: static;
  width: 509px;
  height: 20px;
  left: 0px;
  top: 36px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #3e6d9c;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
}

.comText {
  position: static;
  width: 181px;
  height: 40px;
  left: 47px;
  top: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #3e6d9c;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.checkIcon {
  color: #5cdb95;
}

.bTextStyle {
  position: static;
  width: 140px;
  height: 24px;
  left: 0px;
  top: 0px;
  /* ☑️ Style v0.2/Body/Medium */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 2px 4px;
}

.qTextStyle {
  position: static;
  width: 164px;
  height: 24px;
  left: 0px;
  top: 0px;
  /* ☑️ Style v0.2/Body/Medium */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Neutral/02 */
  color: #8ca6c0;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  /* margin: 0px 0px; */
}

.expTextStyle {
  position: static;
  width: 164px;
  height: 24px;
  left: 0px;
  top: 0px;
  /* ☑️ Style v0.2/Body/Medium */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Neutral/02 */
  color: #8ca6c0;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  /* margin: 0px 4px; */
}

.businIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  margin-top: 30px;
}

.skillTextStyle {
  position: static;
  width: 164px;
  height: 24px;
  left: 0px;
  top: 0px;

  /* ☑️ Style v0.2/Body/Medium */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Neutral/02 */

  color: #8ca6c0;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 4px;
}

.starIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  margin-top: 30px;
  color: #8ca6c0;
}

.certTextStyle {
  position: static;
  width: 164px;
  height: 24px;
  left: 0px;
  top: 0px;

  /* ☑️ Style v0.2/Body/Medium */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Neutral/02 */

  color: #8ca6c0;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 4px;
}

.certIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  margin-top: 30px;
  color: #8ca6c0;
}

.subTextStyle {
  position: static;
  width: 164px;
  height: 24px;
  left: 0px;
  top: 0px;
  /* ☑️ Style v0.2/Body/Medium */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Neutral/02 */
  color: #8ca6c0;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 4px;
}

.subIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  margin-top: 30px;
  color: #8ca6c0;
}

.pIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  margin-top: 30px;
}
.paymentSetup1 {
  padding: 2px 5px;
  font-size: 19px;

  /* color: white; */
  /* background-color:#ced4da; */
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
}
.schIcon {
  left: 12.5%;
  right: 12.5%;
  top: 54.17%;
  bottom: 36.92%;
  padding: 0px 3px;
  font-size: 3px;
  border: 1.25px solid #8ca6c0;
  border-radius: 100%;
  /* margin-top: 30px; */
}

.businIcon {
  /* position: absolute; */
  color: #8ca6c0;
  /* ☑️ Style v0.2/Neutral/02 */
  border: 1.25px solid #8ca6c0;
  margin-top: 20px;
}

.inTextStyling {
  position: static;

  height: 72px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #8ca6c0;
  flex: none;
  order: 0;
  flex-grow: 1;
  /* margin: 0px 10px; */
}

.helpCardStyling {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  position: absolute;
  /* width: 267px; */
  /* height: 64px; */
  left: 1149px;
  top: 576px;
  color: #05386b;
  /* ☑️ Style v0.2/Emotinal/Success/Light */

  background: #acf1d1;
  border-radius: 4px;
}

.helpCenterTextStyle {
  position: static;
  width: 167px;
  height: 24px;
  left: 56px;
  top: 20px;

  /* ☑️ Style v0.2/Headings/05 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 8px;
}
.bioLang {
  position: static;
  left: 0%;
  right: 54.17%;
  top: 0%;
  bottom: 0%;

  /* ☑️ Style v0.2/Captions/Label */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  /* ☑️ Style v0.2/Neutral/01 */

  color: #3e6d9c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
}
.descTextStyling {
  position: static;
  left: 0%;
  right: 97.43%;
  top: 0%;
  bottom: 76.19%;
  /* ☑️ Style v0.2/Captions/Label */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  /* ☑️ Style v0.2/Neutral/01 */
  color: #3e6d9c;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}
.descPragraphStyling {
  position: static;
  left: 1.54%;
  right: 1.54%;
  top: 14.29%;
  bottom: 14.29%;

  /* ☑️ Style v0.2/Forms/01 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* or 154% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 10px;
}
.recordTextStyling {
  position: static;
  width: 131px;
  height: 24px;
  left: 0px;
  top: 0px;

  /* ☑️ Style v0.2/Headings/05 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 24px 0px;
}
.VideoIcons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: static;
  font-size: 10px;
  /* ☑️ Style v0.2/Neutral/06 */
  background: #ffffff;
  /* ☑️ Style v0.2/Neutral/04 */
  /* border: 1px solid #E6EBF0; */
  box-sizing: border-box;
  border-radius: 50px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 350px;
}
.bioMax5 {
  position: static;
  width: 70px;
  height: 16px;
  left: 74px;
  top: 2px;

  /* ☑️ Style v0.2/Captions/Caption Small */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */
  /* ☑️ Style v0.2/Neutral/02 */
  color: #8ca6c0;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}
.skillsTextStyle {
  position: static;
  width: 61px;
  height: 24px;
  left: 0px;
  top: 0px;

  /* ☑️ Style v0.2/Headings/05 */

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* ☑️ Style v0.2/Primary/02 */

  color: #05386b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 16px 0px;
}
.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

/*checkbox1 start*/
.roundtc1 {
  position: relative;
}
.roundtc1 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.roundtc1 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.roundtc1 input[type="checkbox"] {
  visibility: hidden;
}

.roundtc1 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.roundtc1 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox1 end*/

/*checkbox2 start*/
.roundtc2 {
  position: relative;
}
.roundtc2 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.roundtc2 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.roundtc2 input[type="checkbox"] {
  visibility: hidden;
}

.roundtc2 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.roundtc2 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox2 end*/

/*checkbox3 start*/
.roundtc3 {
  position: relative;
}
.roundtc3 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.roundtc3 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.roundtc3 input[type="checkbox"] {
  visibility: hidden;
}

.roundtc3 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.roundtc3 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox3 end*/

/*checkbox4 start*/
.roundtc4 {
  position: relative;
}
.roundtc4 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.roundtc4 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.roundtc4 input[type="checkbox"] {
  visibility: hidden;
}

.roundtc4 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.roundptc4 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox4 end*/

/*checkbox5 start*/
.roundtc5 {
  position: relative;
}
.roundtc5 label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.roundtc5 label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.roundtc5 input[type="checkbox"] {
  visibility: hidden;
}

.roundtc5 input[type="checkbox"]:checked + label {
  background-color: #5cdb95;
  border-color: #5cdb95;
}

.roundtc5 input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/*checkbox5 end*/

.checkbox-round:checked {
  background-color: #5cdb95;
}
.arrowIconStyling {
  color: #05386b;
  margin-left: 60px;
}
.warnTextStyling {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  position: static;
  width: 407px;
  height: 36px;
  left: 370px;
  top: 0px;
  /* ☑️ Style v0.2/Emotinal/Warning/Light */
  background: #fefbe8;
  /* ☑️ Style v0.2/Emotinal/Warning/Dark */
  border: 1px solid #f3d11a;
  box-sizing: border-box;
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 183px;
}
.warnIconStyling {
  color: #f3d11a;
  font-size: 24px;
  margin-bottom: 2px;
}
.warningTextStyling {
  /* position: static; */
  width: 66px;
  height: 24px;
  left: 32px;
  top: 0px;
  /* ☑️ Style v0.2/Headings/05 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}
.wrnMesgTextStyle {
  position: static;
  width: 237px;
  height: 24px;
  left: 126px;
  top: 6px;
  /* ☑️ Style v0.2/Body/Medium */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 16px;
}
.crosIconStyle {
  color: black;
  font-size: 7px;
  margin-left: 48px;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3e6d9c;
  background-color: #ffffff;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e); */
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
.uploadIconStyling {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 25px;
  color: #05386b;
  position: static;
  max-width: 777px;
  height: 36px;
  left: 0px;
  top: 28px;
  /* ☑️ Style v0.2/Neutral/06 */
  background: #ffffff;
  /* ☑️ Style v0.2/Neutral/04 */
  border: 1px dashed #e6ebf0;
  box-sizing: border-box;
  border-radius: 4px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}
.selectTextStyling {
  position: static;
  left: 3.15%;
  right: 71.88%;
  top: 22.22%;
  bottom: 22.22%;
  /* ☑️ Style v0.2/Forms/01 */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */
  /* ☑️ Style v0.2/Primary/02 */
  color: #05386b;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.cameraImage {
  margin-left: 20px;
  margin-bottom: -105px;
  /* margin-top: -10px; */
}

.HiTextStyle {
  position: static;
  width: 194px;
  height: 36px;
  left: 0px;
  top: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #05386b;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.Tutor-card {
  width: 1120px;
  margin-left: 20px;
}
.skillButtonStyling {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 1px;

  position: static;
  width: 190px;
  height: 36px;
  left: 158px;
  top: 0px;

  /* ☑️ Style v0.2/Neutral/05 */

  background: #f8f8fb;
  /* ☑️ Style v0.2/Neutral/04 */

  border: 1px solid #e6ebf0;
  box-sizing: border-box;
  border-radius: 7px;

  /* Inside auto layout */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #05386b;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 5px 12px;
}

@media screen and (max-width: 1200px) {
  .startSidebar {
    width: 360px;
  }
  .main-contents {
    display: block;
  }
  .headCard {
    margin-left: 350px;
    /* margin-top: 920px; */
  }
  .Tutor-card {
    width: 780px;
    margin-right: 50px;
  }
  .user-card {
    width: 780px;
    margin-left: 20px;
  }
  /* .sidebar .sidebar-brand ,
    .sidebar span {
      padding-left: 1rem;
      text-align: center;
    } */
  /* .main-contents {
      margin-left: 0px;
      margin-top: 660px;

    }
    .main-card {
      width:360px;

    } */
}

@media screen and (max-width: 968px) {
  .startSidebar {
    width: 360px;
  }
  .main-contents {
    display: block;
  }
  .headCard {
    margin-left: 350px;
    /* margin-top: 920px; */
  }
  .Tutor-card {
    width: 480px;
    margin-right: 50px;
  }
  .user-card {
    width: 480px;
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  /* .sidebar {
      width: 100px;

    } */
  .startSidebar {
    margin-left: 12px;
    width: 400px;
  }
  .recent-grid {
    grid-template-columns: 100%;
  }
  .main-contents {
    /* margin-left: -32px; */
    /* margin-top: 780px; */
  }
  .warnTextStyling {
    width: 20px;
  }
  .Tutor-card {
    width: 355px;
    /* margin-left: 12px; */
    margin-left: 15px;
  }
  .headCard {
    margin-left: 0px;
    margin-top: 920px;
    width: 402px;
    padding: 10px;
  }
  .user-card {
    width: 360px;
    margin-left: 0px;
  }
  /* .sidebar {
      width: 100%;
      height: auto;
      position: relative;
    } */

  /* .sidebar a {
      float: left;
    } */

  /* div.content {
      margin-left: 0;
    } */
}

@media screen and (max-width: 400px) {
  /* .sidebar a {
      text-align: center;
      float: none;
    } */
  .startSidebar {
    width: 352px;
  }
  .warnTextStyling {
    width: 20px;
  }
  .main-contents {
    margin-left: -15px;
    /* margin-top: 660px; */
    /* margin-right: 10px; */
  }
  .user-card {
    width: 352px;
    margin-left: 15px;
  }
  .headCard {
    width: 353px;
  }
  .Tutor-card {
    width: 352px;
    /* margin-left: -50px; */
  }
}
