// Empty box above the time-column
.e-schedule .e-vertical-view .e-left-indent {
  width: 5rem !important;
}

// The time column
.e-schedule.e-device .e-vertical-view .e-time-cells-wrap {
  width: 5rem !important;
}

// The useless buttons (plus and calendar) on the toolbar
.e-schedule.e-device
  .e-schedule-toolbar
  .e-toolbar-items.e-tbar-pos
  > .e-toolbar-right {
  display: none !important;
}

.e-toolbar-item.e-views.e-agenda {
  display: none !important;
}

// Hiding an appointment's timezone information
.e-popup-content .e-time-zone {
  display: none !important;
}