@keyframes inviteToScroll {
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateX(-7rem);
  }
}
.trigger-scroll {
  align-items: center;
  animation: inviteToScroll 1s ease-out 3 forwards;
  animation-delay: 1s;
  border: 0;
  border-radius: 50%;
  display: flex;
  height: 2rem;
  justify-content: center;
  opacity: 1;
  padding: 0;
  pointer-events: none;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2rem;
  z-index: 10000000000;
  top: calc(50% - 17px);

  @media (min-width: 992px) {
    display: none;
  }
}
