.calendar {
  .fc {
    .fc-toolbar {
      .fc-header-toolbar {
        margin-bottom: 12px;
      }
    }

    .fc-col-header-cell-cushion {
      padding-bottom: 12px;
    }

    .fc-toolbar-title {
      color: var(--bs-secondary);
      font-size: 15px;
      font-weight: 600;
    }

    .fc-daygrid-day-frame {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .fc-day-disabled {
      background-color: white;
    }

    .fc-daygrid-day-events {
      display: none;
    }

    .fc-daygrid-day-top {
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .fc-daygrid-day-number {
      &:hover {
        background: var(--bs-secondary);
        border-radius: 50%;
        width: 28px;
        height: 26px;
        color: white;
      }
    }

    // .fc-daygrid-day.fc-day-today {
    //   background-color: transparent;

    //   .fc-daygrid-day-number {
    //     background: #34c38f;
    //     color: white;
    //     font-weight: bold;
    //     border-radius: 50%;
    //     width: 28px;
    //     height: 26px;
    //   }
    // }

    .fc-daygrid-bg-harness {
      .fc-bg-event {
        height: 26px;
        position: absolute;
        left: 50%;
        top: 50%;
        width: calc(100% - 1rem);
        transform: translate(-50%, -50%);
        background-color: #34c38f;
        border-radius: 1rem;
      }
    }

    .table-bordered th,
    .table-bordered td {
      border: none;
    }

    .fc-next-button,
    .fc-prev-button {
      background-color: var(--bs-secondary);
      border: 1px solid var(--bs-secondary);

      &:is(:hover, :focus) {
        background-color: #3e6d9c;
        border: 1px solid #3e6d9c;
      }
    }

    .fc-day-selected {
      background: #34c38f;
      color: white !important;
      font-weight: bold;
      border-radius: 50%;
      width: 28px;
      height: 26px;
    }
  }
}
