.no-upcoming-session-subheading {
  font-size: 16px;
  // line-height: 1px;
  text-align: center;
  font-weight: 600;
  color: #05386b;
  margin-bottom: 8px;
}

.no-upcoming-session-subheading-2 {
  color: #3e6d9c;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 15px !important  ;
}

.no-upcoming-session-rounded .card {
  border-radius: 0 !important;
}
