@import "../../../assets/scss/styles.scss";

.suggested-link {
  font-size: 12px;
  line-height: 20px;
  color: #48b2e0;
}

.suggested-link-selected {
  color: #05386b;
}

.learner-progress-bar {
  background-color: #ddd;
  border-radius: 6px;
}

#myBar1 {
  height: 6px;
  background-color: #5cdb95;
  text-align: center;
  line-height: 30px;
  color: white;
}

.bs-icon {
  height: 44px;
  width: 44px;
  border-radius: 50px;
  padding: 12px;
}
.choose-topic {
  background-color: #ffffff !important;
  padding: 0;
}
.choose-topic-main {
  background-color: #ffffff;
}

.searchbar {
  .basic-multi-select {
    margin-bottom: 8px;
    font-size: 13px;
    position: relative;

    .select__placeholder,
    .select__input-container,
    .select__value-container--has-value {
      margin-left: 26px;
    }
  }

  .language-search {
    position: absolute;
    top: 12px;
    left: 20px;
  }
}

.language-max {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #8ca6c0;
  margin-left: 8px;
}

.learner-onboarding-img {
  max-width: 350px;
  width: 100%;
  min-width: 270px;
}
